export const PageHeadingButton = ({
  label,
  onClick,
  children,
  isDisabled = false,
  className = `text-white bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600`,
}) => (
  <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-none">
    <button
      disabled={isDisabled}
      type="button"
      onClick={onClick}
      className={`${className} flex rounded-md px-3 py-2 text-center text-sm font-semibold shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
    >
      {children}
      {label}
    </button>
  </div>
);
