import { useRef, useEffect, useState } from 'react';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';

export const SlideOverInputAmount = ({
  name,
  isRequired,
  min,
  max,
  defaultValue,
  prefix = null,
  suffix = null,
}) => {
  const { register, errors } = useSlideOverFormContext();
  const prefixRef = useRef(null);
  const suffixRef = useRef(null);
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [suffixWidth, setSuffixWidth] = useState(0);

  useEffect(() => {
    if (prefixRef.current) setPrefixWidth(prefixRef.current.offsetWidth);
    if (suffixRef.current) setSuffixWidth(suffixRef.current.offsetWidth);
  }, [prefix, suffix]);

  return (
    <div className="flex-1">
      <div className="relative flex items-center">
        {prefix && (
          <div
            ref={prefixRef}
            className="absolute left-0 inset-y-0 flex items-center pl-3"
          >
            <span className="text-gray-500 sm:text-sm">{prefix}</span>
          </div>
        )}

        <input
          id={name}
          type="number"
          name={name}
          className={`block w-full rounded-md border ${
            errors[name] ? 'border-red-500' : 'border-gray-300'
          } py-1.5 text-gray-900 placeholder:text-gray-400 shadow-sm focus:ring-1 focus:ring-inset ${
            errors[name] ? 'focus:ring-red-500' : 'focus:ring-indigo-600'
          } sm:text-sm`}
          style={{
            paddingLeft: prefix ? `${prefixWidth + 12}px` : '0.75rem',
            paddingRight: suffix ? `${suffixWidth + 12}px` : '0.75rem',
          }}
          defaultValue={defaultValue}
          min={min}
          max={max}
          {...register(name, {
            required: isRequired ? 'This input is required.' : false,
            valueAsNumber: true,
            min,
            max,
          })}
          step="any"
        />

        {suffix && (
          <div
            ref={suffixRef}
            className="absolute right-0 inset-y-0 flex items-center pr-3"
          >
            <span className="text-gray-500 sm:text-sm">{suffix}</span>
          </div>
        )}
      </div>
      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};
