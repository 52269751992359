import { Breadcrumbs } from '../atoms/Breadcrumbs';

export const PageHeading = ({
  heading,
  subheading = null,
  children,
  pages,
}) => (
  <div className="mb-4 sm:flex sm:items-center">
    <div className="sm:flex-auto">
      {heading ? (
        <>
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {heading}
          </h1>
          {subheading}
        </>
      ) : (
        <Breadcrumbs pages={pages} />
      )}
    </div>
    {children}
  </div>
);
