export const Checkbox = ({
  name,
  isDisabled = false,
  label,
  isChecked,
  onChange,
}) => (
  <div className="relative">
    <div className="flex items-center">
      {/* Checkbox Input */}
      <input
        id={name}
        name={name}
        type="checkbox"
        className={`${
          isDisabled ? 'cursor-not-allowed bg-gray-100' : ''
        } h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2`}
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
      />

      {/* Label */}
      <label
        htmlFor={name}
        className={`${
          isDisabled ? 'cursor-not-allowed' : ''
        } text-sm text-gray-600`}
      >
        {label}
      </label>
    </div>
  </div>
);
