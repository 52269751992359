export const getTeamTimesheetDataByClientUUIDLogic = async (datas) => {
  const {
    setTimesheetDataSelectionResult,
    callData,
    returnData = false,
  } = datas;

  if (setTimesheetDataSelectionResult) {
    setTimesheetDataSelectionResult(callData);
  }

  if (returnData) {
    return callData;
  }
};
