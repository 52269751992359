import React, { useEffect, useState } from 'react';
import { TreeCheckbox } from './TreeCheckbox';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';
import SearchWithActions from './SearchWithActions';

export const SlideOverInputTreeCheckbox = ({ treeData }) => {
  const { setValue, errors } = useSlideOverFormContext();
  const [filteredTreeData, setFilteredTreeData] = useState(treeData);
  const [checkedState, setCheckedState] = useState({});
  const [treeVersion, setTreeVersion] = useState(0);
  const [originalTreeData, setOriginalTreeData] = useState(treeData); // Store original unfiltered tree data

  useEffect(() => {
    const initializeCheckedState = (nodes) =>
      nodes.reduce((acc, node) => {
        acc[node.id] = node.isChecked || false;
        if (node.children) {
          Object.assign(acc, initializeCheckedState(node.children));
        }
        return acc;
      }, {});

    if (Object.keys(checkedState).length === 0) {
      const initialCheckedState = initializeCheckedState(treeData);
      setCheckedState(initialCheckedState);
      updateFormValues(initialCheckedState); // Sync initial checkedState with form values
    }
    setOriginalTreeData(treeData);
    setFilteredTreeData(treeData);
  }, [treeData]);

  const updateFormValues = (updatedCheckedState) => {
    const selectedItems = extractSelectedItems(
      originalTreeData,
      updatedCheckedState
    );

    // Update form value to match the desired structure
    setValue('timesheetDataSelection', selectedItems);
  };

  const extractSelectedItems = (nodes, checkedState) =>
    nodes
      .filter((parent) => checkedState[parent.id])
      .map((parent) => ({
        parentUUID: parent.id,
        childrenUUID: (parent.children || [])
          .filter((child) => checkedState[child.id])
          .map((child) => {
            // Extract member UUID from composite ID
            const childrenUUID = child.id.substring(parent.id.length + 1);
            return childrenUUID;
          }),
      }));

  const handleCheckChange = (updatedCheckedState) => {
    setCheckedState((prevCheckedState) => ({
      ...prevCheckedState,
      ...updatedCheckedState,
    }));
    updateFormValues(updatedCheckedState);
  };

  const handleSearchChange = (query) => {
    const filterTree = (nodes) =>
      nodes
        .map((node) => {
          const children = node.children ? filterTree(node.children) : [];
          if (
            node.label.toLowerCase().includes(query.toLowerCase()) ||
            children.length > 0
          ) {
            return { ...node, children };
          }
          return null;
        })
        .filter((node) => node !== null);

    setFilteredTreeData(filterTree(treeData));
    setTreeVersion((prev) => prev + 1); // Force re-render
  };

  const selectAll = () => {
    const updatedCheckedState = { ...checkedState };
    const markAllChecked = (nodes) => {
      nodes.forEach((node) => {
        updatedCheckedState[node.id] = true;
        if (node.children) {
          markAllChecked(node.children);
        }
      });
    };

    markAllChecked(treeData);
    setCheckedState(updatedCheckedState);
    setTreeVersion((prev) => prev + 1); // Force re-render
    updateFormValues(updatedCheckedState);
  };

  const unselectAll = () => {
    const updatedCheckedState = { ...checkedState };
    const markAllUnchecked = (nodes) => {
      nodes.forEach((node) => {
        updatedCheckedState[node.id] = false;
        if (node.children) {
          markAllUnchecked(node.children);
        }
      });
    };

    markAllUnchecked(treeData);
    setCheckedState(updatedCheckedState);
    setTreeVersion((prev) => prev + 1); // Force re-render
    updateFormValues(updatedCheckedState);
  };

  return (
    <div className="sm:col-span-2">
      <SearchWithActions
        onSearchChange={handleSearchChange}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
      />
      <TreeCheckbox
        key={treeVersion}
        data={filteredTreeData}
        checkedState={checkedState}
        onCheckChange={handleCheckChange}
        originalTreeData={originalTreeData}
      />
      {/* TODO: Add inputErrorMessage */}
    </div>
  );
};
