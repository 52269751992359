/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useState,
  Fragment,
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';

const SlideOverWarningContext = createContext();
const SlideOverFormContext = createContext();

export const useSlideOverWarningContext = () =>
  useContext(SlideOverWarningContext);
export const useSlideOverFormContext = () => useContext(SlideOverFormContext);

export const SlideOver = ({
  open,
  setOpen,
  title = null,
  subtitle = null,
  children,
  defaultValues,
  leftContent,
  dialogBackgroundClassName = `fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity`,
}) => {
  const [shouldDisplayWarning, setShouldDisplayWarning] = useState(false);
  const timeoutId = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues,
  });

  useEffect(
    () => () => {
      // Clear the timeout when the component unmounts
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    },
    []
  );

  useEffect(() => {
    // Reset the form with new defaultValues when they change
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleRequestClose = () => {
    // If there are unsaved changes, display a warning
    if (Object.keys(dirtyFields).length > 0) {
      setShouldDisplayWarning(true);

      // Set a timeout to hide the warning after 1 second
      timeoutId.current = setTimeout(() => {
        setShouldDisplayWarning(false);
      }, 1000);

      return;
    }

    // Clear any existing timeout when closing normally
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    reset();
    setShouldDisplayWarning(false);
    setOpen(false);
  };

  const warningContextValue = useMemo(
    () => ({
      shouldDisplayWarning,
    }),
    [shouldDisplayWarning]
  );

  const formContextValue = {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    errors,
    dirtyFields,
    isDirty,
  };

  const isSlideOverHandleButtonsDisplayed = Object.keys(dirtyFields).length > 0;

  // Define the width of the Dialog.Panel (max-w-2xl in Tailwind is 672px)
  const panelWidth = 672; // Adjust if you have a different max width

  return (
    <SlideOverWarningContext.Provider value={warningContextValue}>
      <SlideOverFormContext.Provider value={formContextValue}>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-hidden"
            onClose={handleRequestClose}
          >
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className={dialogBackgroundClassName} />
            </Transition.Child>

            {/* Container */}
            <div className="absolute inset-0 overflow-hidden">
              <div className="absolute inset-0 flex">
                {/* Left Content */}
                {leftContent && (
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <div
                      className="flex-1 overflow-y-auto"
                      style={{ marginRight: panelWidth }}
                    >
                      <div className="h-full flex items-center justify-center">
                        {leftContent}
                      </div>
                    </div>
                  </Transition.Child>
                )}

                {/* SlideOver Panel */}
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div
                    className="fixed inset-y-0 right-0 w-screen"
                    style={{ maxWidth: panelWidth }}
                  >
                    <Dialog.Panel className="h-full flex flex-col overflow-y-scroll bg-white shadow-xl">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              {title}
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">{subtitle}</p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="relative text-gray-400 hover:text-gray-500"
                              onClick={handleRequestClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Content */}
                      <div
                        className={`relative flex-1 pd-24 ${
                          isSlideOverHandleButtonsDisplayed ? 'pb-20' : ''
                        }`}
                      >
                        {children}
                      </div>
                    </Dialog.Panel>
                  </div>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </SlideOverFormContext.Provider>
    </SlideOverWarningContext.Provider>
  );
};
