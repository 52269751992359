export const putTeamMembersByUUIDLogic = async (datas) => {
  const {
    fetchInfo,
    callData,
    updateOpenModal,
    updateOpenNotification,
    updateNotificationData,
  } = datas;

  if (callData) {
    if (fetchInfo) {
      await fetchInfo();
    }

    updateOpenModal(false);
    if (updateOpenNotification && updateNotificationData) {
      updateOpenNotification(true);
      updateNotificationData({ ...callData });
    }
    return callData;
  }
};
