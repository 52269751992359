import { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { unparse } from 'papaparse';

import {
  FunnelIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/outline';
import { Breadcrumbs } from '../atoms/Breadcrumbs';
import { SideBarLayout } from '../organisms/SiderBarLayout';
import { SingleScreenTitle } from '../atoms/SingleScreenTitle';
import { SingleScreenSubtitle } from '../atoms/SingleScreenSubtitle';
import { EmptyStates } from '../organisms/EmptyStates';
import {
  formatDateInWords,
  beginningOfYear,
  endOfYear,
} from '../../services/utils/momentUtils';
import { SingleScreenMenu } from '../atoms/SingleScreenMenu';
import { Modal } from '../molecules/Modal';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { DividerWithTitleOnLeft } from '../atoms/DividerWithTitleOnLeft';
import { Feeds } from '../molecules/Feeds';
import { useTeam } from '../../contexts/TeamContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { DeleteModalInput } from '../molecules/DeleteModalInput';
import { Accordion } from '../molecules/Accordion';
import { CategoryFilter } from '../molecules/CategoryFilters';
import { SlideOver } from '../molecules/SlideOver';
import { SlideOverFormContainer } from '../molecules/SliderOverFormContainer';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputText } from '../molecules/SlideOverInputText';
import { SlideOverInputCombobox } from '../molecules/SlideOverInputCombobox';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputColorPicker } from '../molecules/SlideOverInputColorPicker';
import { SlideOverInputCheckbox } from '../molecules/SlideOverInputCheckbox';
import { SlideOverInputItemsToggle } from '../molecules/SlideOverInputItemsToggle';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { renderTextWithLinks } from '../../services/utils/renderTextWithLinks';
import { ModalExportContent } from '../organisms/ModalExportContent';
import { mergeClientAndProjectsForExport } from '../../services/utils/mergeClientAndProjectsForExport';

export default function ProjectScreen() {
  /* Access context values using the useTeam hook */
  const teamData = useTeam();
  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  /* Split the pathname by '/' and get the last segment */
  const segments = pathname.split('/');
  const projectName = decodeURIComponent(segments[segments.length - 1]);

  const [openProjectSlideOver, setOpenProjectSlideOver] = useState(false);
  const [project, setProject] = useState({
    name: '',
    deleteTeamMembersUUIDs: [],
    deleteTeamProjectTagsUUIDs: [],
    deleteTeamProjectBudgetsUUIDs: [],
    teamMembersUUID: [],
    tagsUUIDs: [],
    newTagsToCreate: [],
  });

  const [client, setClient] = useState({});
  const [filtersData, setFiltersData] = useState([]);
  const [clients, setClients] = useState({});
  const [budgets, setBudgets] = useState([]);
  const [projectMembers, setProjectMembers] = useState({});
  const [members, setMembers] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [modalSource, setModalSource] = useState(null);
  const [totalAllocationHours, setTotalAllocationHours] = useState(0);
  const [membersAuthorizedToCharge, setMembersAuthorizedToCharge] = useState(
    []
  );
  const [tags, setTags] = useState([]);
  const [listOfTags, setListOfTags] = useState([]);
  const [activities, setActivities] = useState([]);
  const [userPrivileges, setUserPrivileges] = useState();
  const [itemsToExport, setItemsToExport] = useState([]);
  const modalRef = useRef(null);

  const updateFromDate = (date) => {
    setFromDate(date);
  };

  const updateToDate = (date) => {
    setToDate(date);
  };

  const updateOpenProjectSlideOver = (newOpenSlideOver) => {
    setOpenProjectSlideOver(newOpenSlideOver);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  const updateOpenExportModal = (newOpenExportModal) => {
    setOpenExportModal(newOpenExportModal);
  };

  const updateModalSource = (newModalSource) => {
    setModalSource(newModalSource);
  };

  /* BEGINNING CALL API */

  const putTeamProjectsCall = async (inputData, reset) => {
    const params = {
      uuid: project.uuid,
      teamUUID: project.teamUUID,
      name: inputData.projectName || undefined,
      description: inputData.projectDescription || '',
      notes: inputData.projectNotes || '',
      color: inputData.projectColor || undefined,
      clientUUID: inputData.projectClient || undefined,
      budgetsUUIDs:
        Object.entries(inputData.projectBudgets)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [uuid1, uuid2, ...],
      deleteTeamProjectBudgetsUUIDs:
        Object.entries(inputData.projectBudgets)
          .filter(([key, value]) => value === false)
          .map(([key, value]) => key) || undefined,
      tentative: inputData.projectIsTentative,
      isTagsRequired: inputData.projectIsTagsRequired,
      deleteTeamMembersUUIDs:
        Object.entries(inputData.projectMembers)
          .filter(([key, value]) => value === false)
          .map(([key, value]) => key) || undefined,
      teamMembersUUIDs:
        Object.entries(inputData.projectMembers)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [uuid1, uuid2, ...]
      deleteTeamProjectTagsUUIDs:
        Object.entries(inputData.projectTags)
          .filter(([key, value]) => value === false)
          .map(([key, value]) => key) || undefined,
      tagsUUIDs:
        Object.entries(inputData.projectTags)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key) || undefined, // Get the keys === true as an array [tag1, tag2, ...]
    };

    await dynamicApiCall({
      callName: 'putTeamProjectsByUUID',
      params: {
        ...params,
        updateOpenSlideOver: updateOpenProjectSlideOver,
        reset,
        fetchTeamProjectsPromise,
        updateOpenNotification,
        updateNotificationData,
        navigate,
      },
    });
  };

  /* Get all clients used for modal */
  const fetchTeamClientsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamClientsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setClients,
      },
    });
  };

  const fetchTeamBudgetsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamBudgetsByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setBudgets,
      },
    });
  };

  /* After update, refetch client */
  const fetchTeamClientsByUUIDPromise = async (clientUUID) => {
    await dynamicApiCall({
      callName: 'getTeamClients',
      navigate,
      params: {
        teamUUID: teamData.teamUUID,
        clientUUID,
        setClient,
      },
    });
  };

  /* For modal members of team and filters members */
  const fetchTeamMembersByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamMembersByTeamUUID',
      navigate,
      params: {
        teamUUID,
        setPeople: setMembers,
        setProjectMembers,
      },
    });
  };

  /* For list of members already authorized */
  const fetchTeamProjectsMembersByProjectUUIDPromise = async (
    projectUUID,
    teamUUID
  ) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsMembersByProjectUUID',
      navigate,
      params: {
        projectUUID,
        teamUUID,
        setMembersAuthorizedToCharge,
      },
    });
  };

  /* Used when updating data to refetch without passing by portfolioScreen */
  const fetchTeamProjectsPromise = async ({ projectName }) => {
    await dynamicApiCall({
      callName: 'getTeamProject',
      navigate,
      params: {
        teamUUID: teamData.teamUUID,
        projectName,
        setProject,
      },
    });
  };

  /* Get all allocations for this project */
  const fetchAllocations = async ({ teamUUID, from, to, projectUUID }) => {
    await dynamicApiCall({
      callName: 'getTeamMembersAllocations',
      navigate,
      params: {
        teamUUID,
        from,
        to,
        setDays: setActivities,
        getAllAllocationsByProject: true,
        projectUUID,
      },
    });
  };

  const fetchDeleteProjectCall = async () => {
    await dynamicApiCall({
      callName: 'deleteTeamProjectsByUUID',
      navigate,
      params: {
        uuids: project.uuid,
        teamUUID: teamData.teamUUID,
        updateOpenModal,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Get team project tag */
  const fetchTeamProjectsTagsPromise = async ({
    teamUUID,
    teamProjectUUID,
    setTags,
  }) => {
    await dynamicApiCall({
      callName: 'getTeamProjectsTags',
      navigate,
      params: {
        teamUUID,
        teamProjectUUID,
        setTags,
      },
    });
  };

  /* Get team tags */
  const fetchTeamTagsByTeamUUIDPromise = async (teamUUID) => {
    await dynamicApiCall({
      callName: 'getTeamTagsByTeamUUID',
      navigate,
      params: { teamUUID, setListOfTags },
    });
  };

  /* Check if user can access this page */
  const fetchAccessPagePromise = async (teamUUID, page) => {
    await dynamicApiCall({
      callName: 'getAccessPage',
      navigate,
      params: {
        teamUUID,
        navigate,
        page,
        setUserPrivileges,
        updateNotificationData,
        updateOpenNotification,
      },
    });
  };

  /* Retrieve data to export modal */
  const fetchExportDataPromise = async ({
    itemsToExport,
    format,
    isBudgetSelected,
    isAllocationsSelected,
    allocationStartDate,
    allocationEndDate,
  }) => {
    await dynamicApiCall({
      callName: 'getExportData',
      navigate,
      params: {
        itemsToExport,
        teamUUID: teamData.teamUUID,
        isBudgetSelected,
        format,
        isAllocationsSelected,
        allocationStartDate,
        allocationEndDate,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* ENDING CALL API */

  useEffect(() => {
    if (client && project && Object.keys(project).length > 0) {
      const mergedClient = mergeClientAndProjectsForExport(client, [project]);
      setItemsToExport(mergedClient);
    }
  }, [client, project]);

  useEffect(() => {
    if (teamData.teamUUID) {
      /* Set filters date initial value */
      setFromDate(beginningOfYear());
      setToDate(endOfYear());

      fetchAccessPagePromise(teamData.teamUUID, 'Projects');
      fetchTeamClientsByTeamUUIDPromise(teamData.teamUUID);
      fetchTeamBudgetsByTeamUUIDPromise(teamData.teamUUID);
      fetchTeamMembersByTeamUUIDPromise(teamData.teamUUID);
      /* Get team project tags */
      fetchTeamProjectsTagsPromise({ teamUUID: teamData.teamUUID, setTags });
      /* Get team tags */
      fetchTeamTagsByTeamUUIDPromise(teamData.teamUUID);

      fetchTeamProjectsPromise({
        projectName,
      });
    }
  }, [teamData.teamUUID, projectName]);

  useEffect(() => {
    // Calculate total allocation hours from activities
    const totalHours = activities.reduce(
      (total, activity) =>
        // Ensure we're summing only activities that have allocation hours
        total + (activity?.allocation?.allocationHours || 0),
      0
    );

    setTotalAllocationHours(totalHours);
  }, [activities]);

  useEffect(() => {
    if (project && project.clientUUID) {
      fetchTeamClientsByUUIDPromise(project.clientUUID);

      /* Get team project tags */
      fetchTeamProjectsTagsPromise({
        teamUUID: teamData.teamUUID,
        teamProjectUUID: project.uuid,
        setTags,
      });

      fetchTeamProjectsMembersByProjectUUIDPromise(
        project.uuid,
        teamData.teamUUID
      );
    }
  }, [project.clientUUID]);

  useEffect(() => {
    if (projectMembers.length > 0) {
      const consolidatedCategoryFiltersData = [
        {
          id: 'members',
          name: 'Members',
          options: projectMembers.map((item) => ({
            uuid: item.uuid,
            firstname: item.firstName,
            lastname: item.lastName,
            isSelected: true,
          })),
        },
      ];

      setFiltersData(consolidatedCategoryFiltersData);
    }
  }, [projectMembers]);

  useEffect(() => {
    if (teamData.teamUUID && project.uuid) {
      const requestData = {
        projectUUID: project.uuid,
        teamUUID: teamData.teamUUID,
      };

      if (fromDate && fromDate.trim().length !== 0) {
        requestData.from = fromDate;
      }

      if (toDate && toDate.trim().length !== 0) {
        requestData.to = toDate;
      }

      if (fromDate === null) {
        requestData.from = beginningOfYear();
      }
      if (toDate === null) {
        requestData.to = endOfYear();
      }

      fetchAllocations(requestData);
    }
  }, [fromDate, toDate, teamData.teamUUID, project]);

  useEffect(() => {
    if (
      teamData.teamUUID &&
      project.teamUUID &&
      project.teamUUID !== teamData.teamUUID
    ) {
      /* Redirect back to project page because this project may not exist in this team */
      navigate('/portfolio');
    }
  }, [teamData.teamUUID]);

  return (
    <SideBarLayout>
      {project.name ? (
        <>
          <div className="px-4 h-full sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="min-w-0 flex-1">
                <Breadcrumbs
                  pages={[
                    {
                      name: `Portfolio`,
                      href: '/portfolio',
                      current: false,
                    },
                    {
                      name: client.name,
                      href: `/portfolio/${encodeURIComponent(client.name)}`,
                      initialClient: client,
                      current: false,
                    },
                    {
                      name: project.name,
                      href: null,
                      current: true,
                    },
                  ]}
                />
                <SingleScreenTitle text={project.name} />
                <div className="flex space-x-2">
                  <SingleScreenSubtitle>
                    <CalendarDaysIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>
                      Created on{' '}
                      <time dateTime={project.createdAt}>
                        {formatDateInWords(project.createdAt)}
                      </time>
                    </p>
                  </SingleScreenSubtitle>
                </div>

                {project.description && (
                  <SingleScreenSubtitle>
                    <DocumentTextIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{project.description}</p>
                  </SingleScreenSubtitle>
                )}

                {project.notes && (
                  <SingleScreenSubtitle>
                    <PencilSquareIcon
                      className="h-5 w-5 flex-shrink-0 mr-1"
                      aria-hidden="true"
                    />
                    <p>{renderTextWithLinks(project.notes)}</p>
                  </SingleScreenSubtitle>
                )}
              </div>
              <SingleScreenMenu
                exportOnClick={() => setOpenExportModal(true)}
                updateModalSource={updateModalSource}
                updateOpenModal={updateOpenModal}
                onClick={() =>
                  updateOpenProjectSlideOver(!openProjectSlideOver)
                }
                shouldHideButton={userPrivileges === 'member'}
                modalState={openModal}
                shouldDisableDeleteButton={activities && activities.length > 0}
              />
            </div>
            <Accordion
              title="Filters"
              className="text-indigo-900 bg-indigo-100 rounded-lg"
              icon={<FunnelIcon className="w-5 h-5 mr-1 text-indigo-900" />}
            >
              <CategoryFilter
                filtersData={filtersData}
                initialToDate={toDate}
                initialFromDate={fromDate}
                setFiltersData={setFiltersData}
                onCalendarDateChange={(from, to) => {
                  updateFromDate(from);
                  updateToDate(to);
                }}
              />
            </Accordion>

            <div className="my-2" />
            <DividerWithTitleOnLeft title="Allocation activities" />
            <p className="text-sm text-gray-500">
              Total: {Math.round(totalAllocationHours)}h
            </p>
            <div className="my-6" />
            <Feeds
              filtersData={filtersData}
              activities={activities}
              teamData={teamData}
            />
          </div>
          <Modal open={openModal} setOpen={updateOpenModal} innerRef={modalRef}>
            {modalSource === `edit` ? null : (
              <DeleteModalInput
                labelToCopy={project.name}
                onClickCancel={() => updateOpenModal(false)}
                deleteFunction={fetchDeleteProjectCall}
              />
            )}
          </Modal>

          {/* Export modal */}
          <Modal open={openExportModal} setOpen={updateOpenExportModal}>
            <ModalExportContent
              itemsToExport={itemsToExport}
              fetchExportData={fetchExportDataPromise}
            />
          </Modal>

          {/* Project SlideOver */}
          {project && Object.keys(project).length > 0 && (
            <SlideOver
              open={openProjectSlideOver}
              setOpen={updateOpenProjectSlideOver}
              title="Edit project"
              defaultValues={{
                projectName: project.name,
                projectClient: project.clientUUID,
                projectDescription: project.description,
                projectNotes: project.notes,
                projectColor: project.color,
                projectIsTentative: project.tentative === 1,
                projectIsTagsRequired: project.isTagsRequired === 1,
                projectBudgets: budgets.reduce((acc, budget) => {
                  // Set to true if budget's UUID is in the project.teamBudgets, false otherwise
                  acc[budget.uuid] = new Set(
                    project.budgets.map((budget) => budget.budgetUUID)
                  ).has(budget.uuid);
                  return acc;
                }, {}),
                // project.budgetUUID,
                projectMembers: members.reduce((acc, member) => {
                  // Set to true if member's UUID is in the project.teamMembers, false otherwise
                  acc[member.uuid] = new Set(
                    project.teamMembers.map((member) => member.memberUUID)
                  ).has(member.uuid);
                  return acc;
                }, {}),
                projectTags: listOfTags.reduce((acc, tag) => {
                  // Set to true if tag is active for the current project, false otherwise
                  acc[tag.tagUUID] = project.tags.some(
                    (projectTag) =>
                      projectTag.tagUUID === tag.tagUUID &&
                      projectTag.projectUUID === project.uuid
                  );
                  return acc;
                }, {}),
              }}
            >
              <SlideOverFormContainer onSubmit={putTeamProjectsCall}>
                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Project name" isRequired />
                  <SlideOverInputText name="projectName" isRequired />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Client" isRequired />
                  <SlideOverInputCombobox
                    name="projectClient"
                    placeholder="Research a client"
                    data={clients}
                    isRequired
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel
                    label="Budgets"
                    subLabel="Amount will be equally splitted between each selected budgets."
                    linkData={{ to: `/budgets`, label: `Manage budgets` }}
                  />
                  <SlideOverInputItemsToggle
                    name="projectBudgets"
                    data={budgets.map((budget) => ({
                      ...budget,
                      dataType: 'budgets',
                    }))}
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Description" />
                  <SlideOverInputTextArea name="projectDescription" rows={1} />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Notes" />
                  <SlideOverInputTextArea name="projectNotes" rows={2} />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Color" />
                  <SlideOverInputColorPicker name="projectColor" />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel label="Tentative" />
                  <SlideOverInputCheckbox
                    name="projectIsTentative"
                    label="Tentative"
                    sublabel="If checked, project will be flagged as tentative."
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel
                    label="Members"
                    linkData={{ to: `/members`, label: `Manage members` }}
                  />
                  <SlideOverInputItemsToggle
                    name="projectMembers"
                    data={members.filter((member) => !member.deactivatedAt)} // Filter out deactivated members
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel
                    label="Tags"
                    linkData={{
                      to: `/settings/teams/${encodeURIComponent(
                        teamData.teamName
                      )}/tags`,
                      label: `Manage tags`,
                    }}
                  />
                  <SlideOverInputItemsToggle
                    name="projectTags"
                    data={listOfTags}
                  />
                </SlideOverInputContainer>

                <SlideOverInputContainer>
                  <SlideOverInputLabel
                    label="Allocation settings"
                    subLabel="To enable this option, select at least one tag from Tags."
                  />
                  <SlideOverInputCheckbox
                    name="projectIsTagsRequired"
                    label="Required tags"
                    sublabel="If selected, tags will be required when a member allocates time to this project."
                    dependsOn="projectTags"
                  />
                </SlideOverInputContainer>

                <SlideOverHandleButtons submitLabel="Edit project" />
              </SlideOverFormContainer>
            </SlideOver>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <EmptyStates
            displayFor="admin"
            label="Project not found"
            subLabel="This project does not exist in this team."
            buttonLabel="Back to portfolio"
            onClick={() => {
              navigate('/portfolio');
            }}
            iconBack
          >
            <DocumentMagnifyingGlassIcon
              className="mx-auto h-12 w-12 text-gray-400"
              aria-hidden="true"
            />
          </EmptyStates>
        </div>
      )}
    </SideBarLayout>
  );
}
