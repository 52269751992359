import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { InputErrorMessage } from '../atoms/InputErrorMessage';
import { useSlideOverFormContext } from './SlideOver';
import SearchWithActions from './SearchWithActions';

export const SlideOverInputItemsToggle = ({ name, data }) => {
  const { errors, setValue, watch } = useSlideOverFormContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [preprocessData, setPreprocessData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const identifyAndSetDataType = (data) => {
      if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'tagUUID')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'tags',
          uuid: item.tagUUID,
        }));
        setPreprocessData(modifiedData);
      } else if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'budgets')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'projects',
        }));
        setPreprocessData(modifiedData);
      } else if (
        data.length > 0 &&
        Object.prototype.hasOwnProperty.call(data[0], 'teamUUID')
      ) {
        const modifiedData = data.map((item) => ({
          ...item,
          type: 'members',
        }));
        setPreprocessData(modifiedData);
      } else {
        setPreprocessData(data);
      }
    };

    identifyAndSetDataType(data);
  }, [data]);

  const toggleStates = watch(name) || {};

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (searchQuery) {
          setSearchQuery('');
          event.stopPropagation();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchQuery]);

  const handleToggle = (uuid) => {
    const newState = { ...toggleStates, [uuid]: !toggleStates[uuid] };
    setValue(name, newState, { shouldDirty: true });
  };

  const selectAll = () => {
    const allSelected = preprocessData.reduce(
      (acc, item) => ({ ...acc, [item.uuid]: true }),
      {}
    );
    setValue(name, allSelected, { shouldDirty: true });
  };

  const unselectAll = () => {
    const noneSelected = preprocessData.reduce(
      (acc, item) => ({ ...acc, [item.uuid]: false }),
      {}
    );
    setValue(name, noneSelected, { shouldDirty: true });
  };

  useEffect(() => {
    if (preprocessData) {
      const newFilteredData = preprocessData.filter((item) =>
        item.type === 'tags' ||
        item.type === 'projects' ||
        item.dataType === 'budgets'
          ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
          : `${item.firstName} ${item.lastName}`
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
      );
      setFilteredData(newFilteredData);
    }
  }, [preprocessData, searchQuery]);

  return (
    <div className="sm:col-span-2">
      <SearchWithActions
        onSearchChange={setSearchQuery}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
      />
      <ul
        role="list"
        className="divide-y divide-gray-200 overflow-y-auto max-h-44 mt-4"
      >
        {filteredData.map((item) => (
          <li
            key={item.uuid}
            className="py-4 flex justify-between items-center"
          >
            <div
              role="button"
              tabIndex={0}
              className="flex items-center justify-between w-full text-left"
              onClick={() => handleToggle(item.uuid)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleToggle(item.uuid, e);
                }
              }}
            >
              <div className="flex items-center cursor-pointer">
                <AvatarWithPlaceholder
                  placeholder={
                    item.type === 'tags' ||
                    item.type === 'projects' ||
                    item.dataType === 'budgets'
                      ? ` `
                      : item.type === 'members' &&
                        item.firstName &&
                        item.lastName
                      ? `${item.firstName[0]}${item.lastName[0]}`.toUpperCase()
                      : `-`
                  }
                  className={item.type === 'tags' ? item.colors : 'bg-gray-500'}
                  size="sm"
                />
                <p className="ml-4 text-sm font-medium text-gray-900">
                  {item.type === 'tags' ||
                  item.type === 'projects' ||
                  item.dataType === 'budgets' ? (
                    item.name
                  ) : item.type === 'members' &&
                    item.firstName &&
                    item.lastName ? (
                    `${item.firstName} ${item.lastName}`
                  ) : (
                    <span className="italic font-light text-gray-400">
                      Firstname LASTNAME
                    </span>
                  )}
                </p>
              </div>
              <div className="min-w-11">
                <Switch
                  checked={toggleStates[item.uuid] || false}
                  onChange={() => {}}
                  className={`${
                    toggleStates[item.uuid] ? 'bg-green-600' : 'bg-gray-200'
                  } mr-4 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
                  onClick={(e) => handleToggle(item.uuid, e)}
                >
                  <span className="sr-only">Enable</span>
                  <span
                    className={`${
                      toggleStates[item.uuid]
                        ? 'translate-x-6'
                        : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <InputErrorMessage errors={errors} name={name} />
    </div>
  );
};

export default SlideOverInputItemsToggle;
