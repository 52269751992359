/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  addMonths,
  format,
  getDay,
  subMonths,
  isBefore as dateFnsIsBefore,
  parseISO,
  addWeeks,
  addYears,
} from 'date-fns';
import { SecondaryButton } from './SecondaryButton';
import { IconButton } from './IconButton';
import { InputErrorMessage } from './InputErrorMessage';
import { useSlideOverFormContext } from '../molecules/SlideOver';

// Custom icons for chevron with vertical bar
const ChevronLeftWithBarIcon = ({ className, ...props }) => (
  <svg
    className={`h-5 w-5 ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7 5v14M17 5l-7 7 7 7"
    />
  </svg>
);

const ChevronRightWithBarIcon = ({ className, ...props }) => (
  <svg
    className={`h-5 w-5 ${className}`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17 5v14M7 5l7 7-7 7"
    />
  </svg>
);

// Function to generate month data with correct day alignment
const generateMonthData = (forMonth) => {
  const start = startOfMonth(forMonth);
  const end = endOfMonth(forMonth);
  const daysArray = eachDayOfInterval({ start, end });

  // Adjusting so that 0 is Monday and 6 is Sunday
  let firstDayOfWeek = getDay(start);
  firstDayOfWeek = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;

  // Generate placeholders for days before the first day of the month
  const leadingDays = Array.from({ length: firstDayOfWeek }, () => ({
    date: null,
    isCurrentMonth: false,
    isToday: false,
  }));

  // Map each day to an object and include current month and today checks
  const days = leadingDays.concat(
    daysArray.map((date) => ({
      date: format(date, 'yyyy-MM-dd'),
      isCurrentMonth: true,
      isToday: format(new Date(), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'),
    }))
  );

  return {
    name: `${format(forMonth, 'MMMM')} ${format(forMonth, 'yyyy')}`,
    days,
  };
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const CalendarPicker = ({
  name1 = 'from',
  name2 = 'to',
  openedFrom = null,
  isDisabled = false,
  modalRef = null,
  isRequired = false,
  defaultStartValue,
  defaultEndValue,
  onChange,
}) => {
  const slideOverFormContext = useSlideOverFormContext();

  const { register, errors, setValue, watch } = slideOverFormContext || {};

  const allocatedFrom = (watch && watch(name1)) || null;
  const allocatedTo = (watch && watch(name2)) || null;

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [monthsData, setMonthsData] = useState([]);
  const [visibleMonths, setVisibleMonths] = useState([]);
  const calendarRef = useRef(null);
  const [calendarTopOffset, setCalendarTopOffset] = useState(0);
  const inputContainerRef = useRef(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectingFrom, setSelectingFrom] = useState(true);
  const [hoveredDate, setHoveredDate] = useState(null);
  const prevToDateRef = useRef(null);

  const incrementOptions = [
    { label: '+1M', amount: 1, unit: 'month' },
    { label: '+3M', amount: 3, unit: 'month' },
    { label: '+1Y', amount: 1, unit: 'year' },
  ];

  useEffect(() => {
    setFromDate(allocatedFrom);
    setToDate(allocatedTo);
  }, [allocatedFrom, allocatedTo]);

  useEffect(() => {
    if (defaultStartValue) {
      setFromDate(defaultStartValue);
    }
    if (defaultEndValue) {
      setToDate(defaultEndValue);
    }
  }, [defaultStartValue, defaultEndValue]);

  useEffect(() => {
    if (onChange) {
      onChange(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (
      openedFrom === 'modal' &&
      inputContainerRef?.current &&
      modalRef?.current
    ) {
      const inputRect = inputContainerRef.current.getBoundingClientRect();
      const modalRect = modalRef.current.getBoundingClientRect();
      setCalendarTopOffset(inputRect.bottom - modalRect.top);
    }
  }, [openedFrom, isCalendarOpen, inputContainerRef, modalRef]);

  const isDateInHoverRange = (date) => {
    if (!fromDate || !hoveredDate || !date) return false;
    const from = new Date(fromDate);
    const hovered = new Date(hoveredDate);
    const current = new Date(date);
    return current > from && current < hovered;
  };

  useEffect(() => {
    const months = [];
    const startMonth = subMonths(new Date(), 24);
    const endMonth = addMonths(new Date(), 24);

    for (
      let month = startMonth;
      month <= endMonth;
      month = addMonths(month, 1)
    ) {
      months.push(generateMonthData(month));
    }

    setMonthsData(months);
    const currentMonthName = `${format(new Date(), 'MMMM')} ${format(
      new Date(),
      'yyyy'
    )}`;
    const nextMonthName = `${format(addMonths(new Date(), 1), 'MMMM')} ${format(
      addMonths(new Date(), 1),
      'yyyy'
    )}`;
    setVisibleMonths([currentMonthName, nextMonthName]);
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsCalendarOpen(true);
    }
  };

  const isDateInRange = (date) => {
    if (!fromDate || !toDate || !date) return false;
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const current = new Date(date);
    return current >= from && current <= to;
  };

  const formatInternalDate = (date) => {
    if (!date) return '';
    return format(new Date(date), 'yyyy-MM-dd');
  };

  const isBefore = (date1, date2) =>
    dateFnsIsBefore(parseISO(date1), parseISO(date2));

  const handleDateClick = (day) => {
    if (!day.date) return;

    const internalFormattedDate = formatInternalDate(day.date);

    if (selectingFrom) {
      setFromDate(internalFormattedDate);
      if (setValue) {
        setValue(name1, internalFormattedDate, { shouldDirty: true });
      }

      // Reset toDate if fromDate is after toDate
      if (toDate && isBefore(toDate, internalFormattedDate)) {
        setToDate(null);
        if (setValue) {
          setValue(name2, null, { shouldDirty: true });
        }
      }

      setSelectingFrom(false);
    } else {
      setToDate(internalFormattedDate);
      if (setValue) {
        setValue(name2, internalFormattedDate, { shouldDirty: true });
      }

      // If toDate is before fromDate, adjust fromDate
      if (fromDate && isBefore(internalFormattedDate, fromDate)) {
        setFromDate(internalFormattedDate);
        if (setValue) {
          setValue(name1, internalFormattedDate, { shouldDirty: true });
        }
      }

      setSelectingFrom(true);
    }
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsCalendarOpen(false);
    }
  };

  useEffect(() => {
    const handleMouseDown = (event) => handleClickOutside(event);
    if (isCalendarOpen) {
      window.addEventListener('mousedown', handleMouseDown);
    }
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isCalendarOpen]);

  const shiftMonths = (direction) => {
    const currentIndex = monthsData.findIndex(
      (m) => m.name === visibleMonths[0]
    );
    if (direction === 'left' && currentIndex > 0) {
      setVisibleMonths([
        monthsData[currentIndex - 1].name,
        monthsData[currentIndex].name,
      ]);
    } else if (direction === 'right' && currentIndex < monthsData.length - 2) {
      setVisibleMonths([
        monthsData[currentIndex + 1].name,
        monthsData[currentIndex + 2].name,
      ]);
    }
  };

  const goToFirstMonthOfYear = () => {
    const currentIndex = monthsData.findIndex(
      (m) => m.name === visibleMonths[0]
    );
    const currentMonth = monthsData[currentIndex];
    let currentYear = parseInt(currentMonth.name.split(' ')[1], 10);

    // If already at January, go to previous year
    if (currentMonth.name.startsWith('January')) {
      currentYear -= 1;
    }

    const firstMonthIndex = monthsData.findIndex(
      (m) => m.name === `January ${currentYear}`
    );
    if (firstMonthIndex !== -1) {
      const visible = [monthsData[firstMonthIndex].name];
      if (firstMonthIndex + 1 < monthsData.length) {
        visible.push(monthsData[firstMonthIndex + 1].name);
      }
      setVisibleMonths(visible);
    }
  };

  const goToLastMonthOfYear = () => {
    const currentIndex = monthsData.findIndex(
      (m) => m.name === visibleMonths[0]
    );
    const currentMonth = monthsData[currentIndex];
    let currentYear = parseInt(currentMonth.name.split(' ')[1], 10);

    // If already at December, go to next year
    if (currentMonth.name.startsWith('December')) {
      currentYear += 1;
    }

    const lastMonthIndex = monthsData.findIndex(
      (m) => m.name === `December ${currentYear}`
    );
    if (lastMonthIndex !== -1) {
      const visible = [monthsData[lastMonthIndex].name];
      if (lastMonthIndex + 1 < monthsData.length) {
        visible.push(monthsData[lastMonthIndex + 1].name);
      } else if (lastMonthIndex > 0) {
        visible.unshift(monthsData[lastMonthIndex - 1].name);
      }
      setVisibleMonths(visible);
    }
  };

  const handleIncrement = (amount, unit) => {
    const baseDate = fromDate ? parseISO(fromDate) : new Date();
    let incrementedDate;
    switch (unit) {
      case 'week':
        incrementedDate = addWeeks(baseDate, amount);
        break;
      case 'month':
        incrementedDate = addMonths(baseDate, amount);
        break;
      case 'year':
        incrementedDate = addYears(baseDate, amount);
        break;
      default:
        return;
    }
    const incrementedDateFormatted = format(incrementedDate, 'yyyy-MM-dd');
    setToDate(incrementedDateFormatted);
    if (setValue) {
      setValue(name2, incrementedDateFormatted, { shouldDirty: true });
    }

    // If new toDate is before fromDate, adjust fromDate
    if (fromDate && isBefore(incrementedDateFormatted, fromDate)) {
      setFromDate(incrementedDateFormatted);
      if (setValue) {
        setValue(name1, incrementedDateFormatted, { shouldDirty: true });
      }
    }
  };

  // Function to handle setting today's date and updating the calendar view
  const handleSetToday = () => {
    const today = new Date();
    const todayFormatted = format(today, 'yyyy-MM-dd');
    if (selectingFrom) {
      setFromDate(todayFormatted);
      if (setValue) {
        setValue(name1, todayFormatted, { shouldDirty: true });
      }

      // Reset toDate if fromDate is after toDate
      if (toDate && isBefore(toDate, todayFormatted)) {
        setToDate(null);
        if (setValue) {
          setValue(name2, null, { shouldDirty: true });
        }
      }
    } else {
      setToDate(todayFormatted);
      if (setValue) {
        setValue(name2, todayFormatted, { shouldDirty: true });
      }

      // If toDate is before fromDate, adjust fromDate
      if (fromDate && isBefore(todayFormatted, fromDate)) {
        setFromDate(todayFormatted);
        if (setValue) {
          setValue(name1, todayFormatted, { shouldDirty: true });
        }
      }
    }

    // Update visible months to show today's month
    const currentMonthName = `${format(today, 'MMMM')} ${format(
      today,
      'yyyy'
    )}`;

    const monthIndex = monthsData.findIndex((m) => m.name === currentMonthName);

    if (monthIndex !== -1) {
      const visible = [monthsData[monthIndex].name];
      if (monthIndex + 1 < monthsData.length) {
        visible.push(monthsData[monthIndex + 1].name);
      } else if (monthIndex > 0) {
        visible.unshift(monthsData[monthIndex - 1].name);
      }
      setVisibleMonths(visible);
    }
  };

  const toggleCalendar = (event, target) => {
    event.stopPropagation();

    if (isDisabled) return;

    setIsCalendarOpen(true);
    setSelectingFrom(target === 'from');

    const selectedDate = target === 'from' ? fromDate : toDate;

    if (selectedDate) {
      const dateObj = parseISO(selectedDate);
      const selectedMonthName = `${format(dateObj, 'MMMM')} ${format(
        dateObj,
        'yyyy'
      )}`;

      const monthIndex = monthsData.findIndex(
        (m) => m.name === selectedMonthName
      );

      if (monthIndex !== -1) {
        const visible = [monthsData[monthIndex].name];
        if (monthIndex + 1 < monthsData.length) {
          visible.push(monthsData[monthIndex + 1].name);
        } else if (monthIndex > 0) {
          visible.unshift(monthsData[monthIndex - 1].name);
        }
        setVisibleMonths(visible);
      }
    } else {
      const currentMonthName = `${format(new Date(), 'MMMM')} ${format(
        new Date(),
        'yyyy'
      )}`;
      const nextMonthName = `${format(
        addMonths(new Date(), 1),
        'MMMM'
      )} ${format(addMonths(new Date(), 1), 'yyyy')}`;
      setVisibleMonths([currentMonthName, nextMonthName]);
    }
  };

  useEffect(() => {
    if (prevToDateRef.current === null && toDate !== null) {
      setIsCalendarOpen(false);
    }
    prevToDateRef.current = toDate;
  }, [toDate]);

  return (
    <div
      className={`sm:col-span-2 flex ${
        !openedFrom && 'relative'
      } ring-1 rounded-md ${
        isCalendarOpen ? 'ring-2 ring-indigo-600' : 'ring-gray-300'
      }`}
    >
      <div ref={inputContainerRef} className="relative w-full">
        {/* From Date Input */}
        <button
          type="button"
          onClick={!isDisabled ? (e) => toggleCalendar(e, 'from') : null}
          onKeyDown={!isDisabled ? handleKeyDown : null}
          tabIndex={0}
          aria-label="Toggle calendar from date"
          className={`w-full flex justify-between items-center cursor-pointer rounded-tl-md rounded-bl-md px-3 pb-1 pt-2 ${
            isCalendarOpen && selectingFrom ? `bg-indigo-50` : `bg-white`
          }
          }`}
        >
          <div>
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => toggleCalendar(e, 'from')}
              className="cursor-pointer block text-xs text-left font-medium text-gray-900"
            >
              From
            </div>
            <input
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              value={fromDate || ''}
              readOnly
              name="from"
              id="from"
              {...(register &&
                register('from', {
                  required: isRequired ? `This input is required.` : false,
                }))}
              className={`cursor-pointer border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                isCalendarOpen && selectingFrom ? `bg-indigo-50` : `bg-white`
              }`}
              placeholder="DD/MM/YYYY"
            />
          </div>

          {fromDate && !isDisabled ? (
            <XMarkIcon
              onClick={() => {
                setFromDate(null);
                setToDate(null);
                setSelectingFrom(true);
              }}
              className="absolute right-2 h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
              aria-label="Clear from date"
            />
          ) : null}
        </button>

        {errors && errors.from && (
          <InputErrorMessage errors={errors} name="from" />
        )}
      </div>
      <div className="relative w-full">
        {/* To Date Input */}
        <button
          type="button"
          onClick={!isDisabled ? (e) => toggleCalendar(e, 'to') : null}
          onKeyDown={!isDisabled ? handleKeyDown : null}
          tabIndex={0}
          aria-label="Toggle calendar to date"
          className={`w-full flex justify-between items-center cursor-pointer rounded-tr-md rounded-br-md px-3 pb-1 pt-2 border-l ${
            isCalendarOpen && !selectingFrom && fromDate
              ? `bg-indigo-50`
              : `bg-white`
          }`}
        >
          <div>
            <div
              role="button"
              tabIndex={0}
              onClick={(e) => toggleCalendar(e, 'to')}
              className="cursor-pointer block text-xs text-left font-medium text-gray-900"
            >
              To
            </div>
            <input
              type="date"
              onKeyDown={(e) => e.preventDefault()}
              value={toDate || ''}
              readOnly
              {...(register &&
                register('to', {
                  required: isRequired ? `This input is required.` : false,
                }))}
              name="to"
              id="to"
              className={`cursor-pointer block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 ${
                isCalendarOpen && !selectingFrom && fromDate
                  ? `bg-indigo-50`
                  : `bg-white`
              }`}
              placeholder="DD/MM/YYYY"
            />
          </div>
          {toDate && !isDisabled && (
            <XMarkIcon
              onClick={() => {
                setToDate(null);
                setSelectingFrom(false);
              }}
              className="absolute right-2 h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700"
              aria-label="Clear to date"
            />
          )}
        </button>
        {errors && errors.to && <InputErrorMessage errors={errors} name="to" />}
      </div>

      {/* Modal for the calendar */}
      {isCalendarOpen && (
        <div
          className={`absolute z-50 ${
            openedFrom === 'modal'
              ? `top-[${calendarTopOffset}px]`
              : 'top-full right-0 mt-[2px]'
          } bg-smoke-light flex shadow-lg`}
          style={{
            top: openedFrom === 'modal' ? `${calendarTopOffset}px` : undefined,
          }}
        >
          <div
            ref={calendarRef}
            className="relative p-4 bg-white w-max max-w-2xl flex-col flex rounded-lg shadow-lg"
          >
            <div
              aria-modal="true"
              role="dialog"
              className="grid grid-cols-1 gap-x-8 md:grid-cols-2"
            >
              {monthsData
                .filter((month) => visibleMonths.includes(month.name))
                .map((month, monthIdx) => (
                  <section
                    key={monthIdx}
                    className={classNames(
                      monthIdx === monthsData.length - 1 && 'hidden md:block',
                      'text-center'
                    )}
                  >
                    <div className="flex items-center justify-between mb-2">
                      {monthIdx === 0 && (
                        <div className="flex space-x-1">
                          <button
                            type="button"
                            onClick={goToFirstMonthOfYear}
                            className="flex items-center justify-center py-1 text-gray-400 hover:text-gray-500"
                          >
                            <ChevronLeftWithBarIcon aria-hidden="true" />
                          </button>
                          <button
                            type="button"
                            onClick={() => shiftMonths('left')}
                            className="flex items-center justify-center py-1 text-gray-400 hover:text-gray-500"
                          >
                            <ChevronLeftIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      )}
                      <h2 className="text-sm font-semibold text-gray-900">
                        {month.name}
                      </h2>
                      {monthIdx === 1 && (
                        <div className="flex space-x-1">
                          <button
                            type="button"
                            onClick={() => shiftMonths('right')}
                            className="flex items-center justify-center py-1 text-gray-400 hover:text-gray-500"
                          >
                            <ChevronRightIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </button>
                          <button
                            type="button"
                            onClick={goToLastMonthOfYear}
                            className="flex items-center justify-center py-1 text-gray-400 hover:text-gray-500"
                          >
                            <ChevronRightWithBarIcon aria-hidden="true" />
                          </button>
                        </div>
                      )}
                    </div>
                    {/* Calendar Grid */}
                    <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
                      <div>Mon</div>
                      <div>Tue</div>
                      <div>Wed</div>
                      <div>Thu</div>
                      <div>Fri</div>
                      <div>Sat</div>
                      <div>Sun</div>
                    </div>
                    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg text-sm">
                      {month.days.map((day, dayIdx) => (
                        <button
                          key={dayIdx}
                          type="button"
                          onClick={() => handleDateClick(day)}
                          onMouseEnter={() => setHoveredDate(day.date)}
                          onMouseLeave={() => setHoveredDate(null)}
                          className={classNames(
                            'relative py-1.5 focus:z-20',
                            day.date && day.isCurrentMonth
                              ? 'hover:bg-indigo-200'
                              : null,
                            !day.date && 'bg-white pointer-events-none',
                            formatInternalDate(day.date) === fromDate &&
                              'bg-indigo-600 font-semibold text-white',
                            formatInternalDate(day.date) === toDate &&
                              'bg-indigo-600 font-semibold text-white',
                            isDateInRange(formatInternalDate(day.date)) &&
                              'bg-indigo-50',
                            isDateInHoverRange(formatInternalDate(day.date)) &&
                              !selectingFrom &&
                              'bg-indigo-50'
                          )}
                        >
                          {day.date ? (
                            <time
                              dateTime={day.date}
                              className={classNames(
                                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                day.isToday
                                  ? 'text-indigo-600'
                                  : 'text-gray-900',
                                formatInternalDate(day.date) === fromDate
                                  ? 'text-white'
                                  : '',
                                formatInternalDate(day.date) === toDate
                                  ? 'text-white'
                                  : '',
                                formatInternalDate(day.date) === fromDate
                                  ? 'bg-indigo-600'
                                  : '',
                                formatInternalDate(day.date) === toDate
                                  ? 'bg-indigo-600'
                                  : ''
                              )}
                            >
                              {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                          ) : null}
                        </button>
                      ))}
                    </div>
                  </section>
                ))}
            </div>
            <div className="flex mt-2 justify-end space-x-2">
              <SecondaryButton
                id="setToday"
                label="Today"
                onClick={handleSetToday}
              />

              {/* Increment Buttons and Controls */}
              {incrementOptions.map((option) => (
                <SecondaryButton
                  key={option.label}
                  id={`increment-${option.label}`}
                  label={option.label}
                  onClick={() => handleIncrement(option.amount, option.unit)}
                />
              ))}

              <SecondaryButton
                id="resetDates"
                label="Reset"
                onClick={() => {
                  setFromDate(null);
                  setToDate(null);
                  setSelectingFrom(true);
                }}
              />

              <IconButton
                id="close"
                label="Close"
                onClick={() => setIsCalendarOpen(false)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
