import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export const TableCellDropdownMenu = ({
  data,
  index,
  customClassName,
  dropdownItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [dropdownStyles, setDropdownStyles] = useState({});

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (isOpen && buttonRef.current && dropdownRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = dropdownRef.current.offsetWidth;
      setDropdownStyles({
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.right + window.scrollX - dropdownWidth}px`,
      });
    }
  }, [isOpen]);

  const handleItemClick = (itemOnClick) => {
    setIsOpen(false);
    buttonRef.current?.focus();
    if (itemOnClick) itemOnClick();
  };

  const dropdownContent = (
    <div
      ref={dropdownRef}
      style={dropdownStyles}
      className="absolute z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
      onClick={(e) => e.stopPropagation()} // Prevent click from triggering parent onClick
      role="menu"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setIsOpen(false);
        }
      }}
    >
      {dropdownItems.map((item, idx) => (
        <div
          key={idx}
          className={item.hasSeparator ? 'border-b border-gray-200' : ''}
        >
          <button
            type="button"
            className={`block w-full px-4 py-2 text-left text-sm ${
              item.textColor || 'text-gray-700'
            } hover:bg-gray-100`}
            onClick={() => handleItemClick(item.onClick)}
          >
            {item.label}
          </button>
        </div>
      ))}
    </div>
  );

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap text-xs text-gray-500',
        customClassName
      )}
    >
      <div
        className="relative inline-block text-left rounded-lg hover:bg-gray-200"
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }
        }}
      >
        <button
          ref={buttonRef}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          className="px-1 py-1 inline-flex justify-center w-full text-sm font-medium text-gray-700 rounded-md focus:outline-none focus:bg-gray-200"
        >
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M6 12h.01M12 12h.01M18 12h.01"
            />
          </svg>
        </button>
      </div>
      {isOpen && createPortal(dropdownContent, document.body)}
    </td>
  );
};
