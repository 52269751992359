import * as React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NotificationProvider } from './contexts/NotificationContext';

import { TeamProvider } from './contexts/TeamContext';
import LoginScreen from './components/screens/LoginScreen';
import SignupScreen from './components/screens/SignupScreen';
import AskTokenToResetPasswordScreen from './components/screens/AskTokenToResetPasswordScreen';
import ResetPasswordScreen from './components/screens/ResetPasswordScreen';
import CreateTeam from './components/screens/CreateTeam';
import SetupTeam from './components/screens/SetupTeam';
import AllocationsScreen from './components/screens/AllocationsScreen';
import MembersScreen from './components/screens/MembersScreen';
import ProjectScreen from './components/screens/ProjectScreen';
import PortfolioScreen from './components/screens/PortfolioScreen';
import BudgetsScreen from './components/screens/BudgetsScreen';
import ClientScreen from './components/screens/ClientScreen';
import UnauthorizedScreen from './components/screens/errors/UnauthorizedScreen';
import ErrorsScreen from './components/screens/errors/ErrorsScreen';
import { SettingsPlansAndBillingScreen } from './components/screens/SettingsPlansAndBillingScreen';
import { SettingsGeneralScreen } from './components/screens/SettingsGeneralScreen';
import { SettingsHoursAndCurrencyScreen } from './components/screens/SettingsHoursAndCurrencyScreen';
import { SettingsTagsScreen } from './components/screens/SettingsTagsScreen';
import ConfirmUserMailFromMailScreen from './components/screens/ConfirmUserMailFromMailScreen';
import ConfirmTransferOwnershipScreen from './components/screens/ConfirmTransferOwnershipScreen';
import NotFoundScreen from './components/screens/errors/NotFoundScreen';
import BudgetScreen from './components/screens/BudgetScreen';

if (process.env.REACT_APP_ENV === `production`) {
  Sentry.init({
    dsn: 'https://48cf6dbc8630590a1c2d6c00d0f0b77d@o4506536244412416.ingest.sentry.io/4506548262076416',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginScreen />,
  },
  {
    path: '/signup',
    element: <SignupScreen />,
  },
  {
    path: '/resetPassword',
    element: <AskTokenToResetPasswordScreen />,
  },
  {
    path: '/resetPassword/:resetToken/:mail?',
    element: <ResetPasswordScreen />,
  },
  {
    path: 'team/create',
    element: <CreateTeam />,
  },
  {
    path: 'team/:teamUUID/setup',
    element: <SetupTeam />,
  },
  {
    path: 'unauthorized',
    element: <UnauthorizedScreen />,
  },
  {
    path: '/',
    element: <AllocationsScreen />,
  },
  {
    path: 'allocations',
    element: <AllocationsScreen />,
  },
  {
    path: 'members',
    element: <MembersScreen />,
  },
  {
    path: 'portfolio',
    element: <PortfolioScreen />,
  },
  {
    path: 'portfolio/:clientSlug',
    element: <ClientScreen />,
  },
  {
    path: 'portfolio/:clientSlug/:projectSlug',
    element: <ProjectScreen />,
  },
  {
    path: 'budgets',
    element: <BudgetsScreen />,
  },
  {
    path: 'budgets/:budgetSlug',
    element: <BudgetScreen />,
  },
  {
    path: 'errors',
    element: <ErrorsScreen />,
  },
  {
    path: 'settings/teams/:teamsSlug/plans&billing',
    element: <SettingsPlansAndBillingScreen />,
  },
  {
    path: 'settings/teams/:teamsSlug/general',
    element: <SettingsGeneralScreen />,
  },
  {
    path: 'settings/teams/:teamsSlug/hours&currency',
    element: <SettingsHoursAndCurrencyScreen />,
  },
  {
    path: 'settings/teams/:teamsSlug/tags',
    element: <SettingsTagsScreen />,
  },
  {
    path: 'confirm/mail/:authToken/:confirmMailToken',
    element: <ConfirmUserMailFromMailScreen />,
  },
  {
    path: 'confirm/transferOwnership/:transferOwnershipToken',
    element: <ConfirmTransferOwnershipScreen />,
  },
  {
    path: '*',
    element: <NotFoundScreen />,
  },
]);

createRoot(document.getElementById('root')).render(
  <div className="h-screen bg-slate-50">
    <div className="h-full">
      <TeamProvider>
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>
      </TeamProvider>
    </div>
  </div>
);
