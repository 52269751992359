import { useEffect, useState } from 'react';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { useSlideOverFormContext } from '../molecules/SlideOver';
import { SlideOverHandleButtons } from '../molecules/SlideOverHandleButtons';
import { SlideOverInputAmount } from '../molecules/SlideOverInputAmount';
import { SlideOverInputCheckbox } from '../molecules/SlideOverInputCheckbox';
import { SlideOverInputCombobox } from '../molecules/SlideOverInputCombobox';
import { SlideOverInputContainer } from '../molecules/SlideOverInputContainer';
import { SlideOverInputLabel } from '../molecules/SlideOverInputLabel';
import { SlideOverInputTextArea } from '../molecules/SlideOverInputTextArea';
import { SlideOverInputTreeCheckbox } from '../molecules/SlideOverInputTreeCheckbox';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';

export default function TimesheetFormContent({ clients, teamData }) {
  const { watch, setValue } = useSlideOverFormContext();

  const shouldDisplayBudgetAmount = watch(
    'shouldDisplayAllocationsHoursInManDay'
  );
  const selectedClient = watch('timesheetClient');
  const startDate = watch('from');
  const endDate = watch('to');

  const isClientSelected = Boolean(selectedClient);
  const isDateRangeSelected = Boolean(startDate && endDate);

  const [treeData, setTreeData] = useState([]);
  const [timesheetDataSelectionResult, setTimesheetDataSelectionResult] =
    useState(null);

  const mapItemsToTreeData = (projects) =>
    projects.map((project) => ({
      id: project.uuid,
      label: project.name,
      isChecked: true,
      children: project.members.map((member) => ({
        id: `${project.uuid}-${member.uuid}`, // Composite ID
        label:
          member.userFirstName && member.userLastName
            ? `${member.userFirstName} ${member.userLastName}`
            : member.userMail,
        isChecked: true,
      })),
    }));

  /* BEGINNING CALL API */
  const fetchTeamTimesheetDataByClientUUIDPromise = async (inputData) => {
    const params = {
      clientUUID: inputData.selectedClient,
      startedAt: inputData.startDate,
      endedAt: inputData.endDate,
      teamUUID: inputData.teamUUID,
    };

    await dynamicApiCall({
      callName: 'getTeamTimesheetDataByClientUUID',
      params: { ...params, setTimesheetDataSelectionResult },
    });

    /* END CALL API */
  };

  useEffect(() => {
    if (isClientSelected && isDateRangeSelected) {
      fetchTeamTimesheetDataByClientUUIDPromise({
        selectedClient,
        startDate,
        endDate,
        teamUUID: teamData.teamUUID,
      });
    }
  }, [selectedClient, startDate, endDate, teamData]);

  useEffect(() => {
    if (timesheetDataSelectionResult) {
      setValue('timesheetDataSelectionResult', timesheetDataSelectionResult, {
        shouldDirty: true,
      });

      const mappedTreeData = mapItemsToTreeData(
        timesheetDataSelectionResult.projects
      );
      setTreeData(mappedTreeData);
    }
  }, [timesheetDataSelectionResult]);

  useEffect(() => {
    // Set default value for hoursInManDayRate if not already set
    if (!watch('hoursInManDayRate') && teamData.teamMaxHours) {
      setValue('hoursInManDayRate', teamData.teamMaxHours);
    }
  }, [teamData, setValue, watch]);

  return (
    <>
      <SlideOverInputContainer>
        <SlideOverInputLabel label="Client" isRequired />
        <SlideOverInputCombobox
          name="timesheetClient"
          placeholder="Research a client"
          data={clients}
          isRequired
        />
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel label="Date range" isRequired />
        <CalendarPicker isRequired name1="from" name2="to" />
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel label="Timesheet data" isRequired />
        {!isClientSelected || !isDateRangeSelected ? (
          <p className="text-informative col-span-2 animate-pulse">
            Please select{' '}
            {`${!isClientSelected ? 'a client' : ''}${
              !isClientSelected && !isDateRangeSelected ? ' and ' : ''
            }${!isDateRangeSelected ? '"a date range"' : ''}`}{' '}
            to proceed.
          </p>
        ) : treeData.length === 0 ? (
          <p className="text-informative col-span-2">
            No project associated with this client.
          </p>
        ) : (
          <SlideOverInputTreeCheckbox treeData={treeData} />
        )}
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel
          label="Man days"
          isRequired={!!shouldDisplayBudgetAmount}
        />
        <SlideOverInputCheckbox
          name="shouldDisplayAllocationsHoursInManDay"
          label="Show hours as man days"
        />
        {shouldDisplayBudgetAmount && (
          <>
            <div className="col-span-1" />
            <SlideOverInputAmount
              name="hoursInManDayRate"
              isRequired={!!shouldDisplayBudgetAmount}
              min={1}
              max={24}
              defaultValue={teamData.teamMaxHours}
              prefix="Hours :"
              suffix="/ man day"
            />
          </>
        )}
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel label="Amounts" />
        <SlideOverInputCheckbox
          name="shouldDisplayAllocationsHoursInCurrency"
          label="Show amount columns"
        />
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel label="Tags" />
        <SlideOverInputCheckbox
          name="shouldDisplayAllocationsTags"
          label="Show tags columns"
        />
      </SlideOverInputContainer>

      <SlideOverInputContainer>
        <SlideOverInputLabel label="Footer" />
        <SlideOverInputTextArea name="timesheetFooter" rows={2} />
      </SlideOverInputContainer>

      <SlideOverHandleButtons
        note="Note, Synkros does not retain timesheet history"
        submitLabel="Generate"
      />
    </>
  );
}
