import React, { useState } from 'react';

const SearchWithActions = ({ onSearchChange, onSelectAll, onUnselectAll }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onSearchChange(e.target.value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    onSearchChange('');
  };

  return (
    <div className="w-full flex items-center">
      <div className="relative w-full">
        <input
          type="text"
          placeholder="Research..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md placeholder-gray-400"
        />
        {searchQuery && (
          <button
            type="button"
            onClick={clearSearch}
            className="absolute inset-y-0 right-0 pr-3 flex items-center"
          >
            <svg
              className="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        )}
      </div>

      <div className="flex gap-x-2 ml-4">
        <button
          type="button"
          onClick={onSelectAll}
          className="text-xs text-indigo-500 hover:text-indigo-700"
        >
          All
        </button>
        <button
          type="button"
          onClick={onUnselectAll}
          className="text-xs text-red-600 hover:text-red-800"
        >
          Unselect
        </button>
      </div>
    </div>
  );
};

export default SearchWithActions;
