const XLSX = require('xlsx');

export function exportDataInXlsx(data, filename) {
  // Convert data array to worksheet
  const worksheet = XLSX.utils.json_to_sheet(data);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Write to XLSX file
  XLSX.writeFile(workbook, `${filename}.xlsx`);
}

export function exportDataInCsv(data, fileName) {
  // Helper function to encapsulate values in double quotes and handle missing values
  const convertToCSV = (data) => {
    const header = Object.keys(data[0])
      .map((key) => `"${key}"`)
      .join(','); // CSV header with double quotes

    const rows = data.map((row) =>
      Object.values(row)
        .map(
          (value) => (value == null ? '""' : `"${value}"`) // Skip null/undefined values
        )
        .join(',')
    );

    return [header, ...rows].join('\n');
  };

  const csvContent = convertToCSV(data);

  // To download as CSV file in browser
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  link.click();
}
