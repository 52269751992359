export const LightAmountConsumptionChart = ({
  min = 0,
  max = 1,
  value,
  label,
}) => {
  const progressPercentage = Math.min(((value - min) / (max - min)) * 100, 100);
  const isOverLimit = value > max;

  return (
    <div className="w-full">
      <div className="flex justify-between mb-1">
        <span className="text-sm text-gray-700 font-medium">{label}</span>
        <span className="text-sm text-gray-500">{`${value} of ${max} used`}</span>
      </div>
      <div className="mt-2 h-1 bg-gray-200 rounded-full overflow-hidden">
        <div
          className={`h-full transition-all duration-300 ${
            isOverLimit ? 'bg-red-500' : 'bg-indigo-500'
          }`}
          style={{ width: `${progressPercentage}%` }}
        />
      </div>
    </div>
  );
};
