import { navigateTo } from './navigateResponses';
import { postTeamProjectsLogic } from './logicByCalls/postTeamProjectsLogic';
import { postTeamBudgetsLogic } from './logicByCalls/postTeamBudgetsLogic.js';
import { getTeamsByOwnerUUIDLogic } from './logicByCalls/getTeamsByOwnerUUIDLogic';
import { putTeamClientsLogic } from './logicByCalls/putTeamClientsLogic';
import { putTeamBudgetsLogic } from './logicByCalls/putTeamBudgetsLogic';
import { getTeamClientsByTeamUUIDLogic } from './logicByCalls/getTeamClientsByTeamUUIDLogic';
import { getTeamBudgetsByTeamUUIDLogic } from './logicByCalls/getTeamBudgetsByTeamUUIDLogic';
import { getTeamMembersByTeamUUIDLogic } from './logicByCalls/getTeamMembersByTeamUUIDLogic';
import { getPrivilegesLogic } from './logicByCalls/getPrivilegesLogic';
import { putTeamMembersByUUIDLogic } from './logicByCalls/putTeamMembersByUUIDLogic';
import { getContractsByAuthTokenLogic } from './logicByCalls/getContractsByAuthTokenLogic';
import { postInvitedUsersByMailAndTeamUUIDLogic } from './logicByCalls/postInvitedUsersByMailAndTeamUUIDLogic';
import { deleteTeamClientsLogic } from './logicByCalls/deleteTeamClientsLogic';
import { deleteTeamBudgetsByUUIDLogic } from './logicByCalls/deleteTeamBudgetsByUUIDLogic';
import { putUserByUUIDLogic } from './logicByCalls/putUserByUUIDLogic';
import { postTeamClientsLogic } from './logicByCalls/postTeamClientsLogic';
import { getTeamProjectsByTeamClientUUIDLogic } from './logicByCalls/getTeamProjectsByTeamClientUUIDLogic';
import { postInvitedUsersByMailAndTeamUUIDAndProjectUUIDLogic } from './logicByCalls/postInvitedUsersByMailAndTeamUUIDAndProjectUUIDLogic';
import { postTeamsLogic } from './logicByCalls/postTeamsLogic';
import { getTeamTagsByTeamUUIDLogic } from './logicByCalls/getTeamTagsByTeamUUIDLogic';
import { putTeamProjectsByUUIDLogic } from './logicByCalls/putTeamProjectsByUUIDLogic';
import { getTeamProjectLogic } from './logicByCalls/getTeamProjectLogic';
import { getTeamMembersAllocationsByTeamAndProjectUUIDLogic } from './logicByCalls/getTeamMembersAllocationsByTeamAndProjectUUIDLogic';
import { getTeamProjectsMembersByProjectUUIDLogic } from './logicByCalls/getTeamProjectsMembersByProjectUUIDLogic';
import { deleteTeamProjectsByUUID } from './logicByCalls/deleteTeamProjectsByUUID.js';
import { getTeamProjectsByTeamUUIDLogic } from './logicByCalls/getTeamProjectsByTeamUUIDLogic';
import { getTeamProjectsMembersByTeamAndMemberUUIDLogic } from './logicByCalls/getTeamProjectsMembersByTeamAndMemberUUIDLogic';
import { getTeamMembersAllocationsLogic } from './logicByCalls/getTeamMembersAllocationsLogic';
import { getTeamProjectsTagsLogic } from './logicByCalls/getTeamProjectsTagsLogic';
import { postTeamMembersAllocationsLogic } from './logicByCalls/postTeamMembersAllocationsLogic';
import { getTeamMembersAllocationsFiltersLogic } from './logicByCalls/getTeamMembersAllocationsFiltersLogic';
import { putTeamMembersAllocationsLogic } from './logicByCalls/putTeamMembersAllocationsLogic.js';
import { putTeamTagsByUUIDLogic } from './logicByCalls/putTeamTagsByUUIDLogic';
import { postLoginLogic } from './logicByCalls/postLoginLogic';
import { postLoginLogic401 } from './logicByCalls/401/postLoginLogic401';
import { getAuthTokenValidityLogic } from './logicByCalls/getAuthTokenValidityLogic';
import { getAuthTokenValidityLogic401 } from './logicByCalls/401/getAuthTokenValidityLogic401';
import { putPasswordByResetTokenLogic } from './logicByCalls/putPasswordByResetTokenLogic.js';
import { putPasswordByResetTokenLogic401 } from './logicByCalls/401/putPasswordByResetTokenLogic401';
import { postLoginLogic303 } from './logicByCalls/303/postLoginLogic303';
import { postUserSignupLogic } from './logicByCalls/postUserSignupLogic';
import { postUserSignupLogic300 } from './logicByCalls/300/postUserSignupLogic300';
import { postUserSignupLogic303 } from './logicByCalls/303/postUserSignupLogic303';
import { postPasswordResetTokenByUserUUIDLogic } from './logicByCalls/postPasswordResetTokenByUserUUIDLogic';
import { postPasswordResetTokenByUserUUIDLogic303 } from './logicByCalls/303/postPasswordResetTokenByUserUUIDLogic303';
import { postTeamTagsLogic } from './logicByCalls/postTeamTagsLogic';
import { getUsersByAuthTokenLogic } from './logicByCalls/getUsersByAuthTokenLogic';
import { deleteTeamMembersByUUIDLogic } from './logicByCalls/deleteTeamMembersByUUIDLogic';
import { getTeamProjectsMilesstonesLogic } from './logicByCalls/getTeamProjectsMilesstonesLogic';
import { deleteTeamClientsLogic500 } from './logicByCalls/500/deleteTeamClientsLogic500';
import { defaultLogic500 } from './logicByCalls/500/defaultLogic500';
import { defaultLogic403 } from './logicByCalls/403/defaultLogic403';
import { getTimezoneLogic } from './logicByCalls/getTimezoneLogic';
import { getTeamsByUUIDLogic } from './logicByCalls/getTeamsByUUIDLogic';
import { putTeamsByUUIDLogic } from './logicByCalls/putTeamsByUUIDLogic';
import { getCountryLogic } from './logicByCalls/getCountryLogic';
import { getBillingPlanByTeamUUIDLogic } from './logicByCalls/getBillingPlanByTeamUUIDLogic';
import { getCurrencyLogic } from './logicByCalls/getCurrencyLogic';
import { deleteTeamsByUUIDLogic } from './logicByCalls/deleteTeamsByUUIDLogic';
import { deleteTeamTagsByUUIDLogic } from './logicByCalls/deleteTeamTagsByUUIDLogic';
import { postStripePortalSessionLogic } from './logicByCalls/postStripePortalSessionLogic';
import { postStripeCheckoutSessionLogic } from './logicByCalls/postStripeCheckoutSessionLogic';
import { postBillingPlanByStripeCheckoutSessionIDLogic } from './logicByCalls/postBillingPlanByStripeCheckoutSessionIDLogic';
import { getTeamClientsLogic } from './logicByCalls/getTeamClientsLogic';
import { getTeamBudgetsLogic } from './logicByCalls/getTeamBudgetsLogic';
import { getAccessPageLogic } from './logicByCalls/getAccessPageLogic';
import { putUserConfirmMailLogic401 } from './logicByCalls/401/putUserConfirmMailLogic401';
import { putUserConfirmMailLogic } from './logicByCalls/putUserConfirmMailLogic';
import { postNewConfirmationMailLogic } from './logicByCalls/postNewConfirmationMailLogic';
import { postNewConfirmationMailLogic401 } from './logicByCalls/401/postNewConfirmationMailLogic401';
import { postPasswordByInvitedUserLogic401 } from './logicByCalls/401/postPasswordByInvitedUserLogic401';
import { postPasswordByInvitedUserLogic } from './logicByCalls/postPasswordByInvitedUserLogic';
import { putUserByUUIDLogic500 } from './logicByCalls/500/putUserByUUIDLogic500.js';
import { putTeamClientsLogic500 } from './logicByCalls/500/putTeamClientsLogic500.js';
import { postTeamTransferOwnershipLogic } from './logicByCalls/postTeamTransferOwnershipLogic';
import { putConfirmTeamTransferOwnershipLogic } from './logicByCalls/putConfirmTeamTransferOwnershipLogic';
import { getCountryByTeamUUIDLogic } from './logicByCalls/getCountryByTeamUUIDLogic';
import { getTeamMembersPreferencesAndSetOptionsLogic } from './logicByCalls/getTeamMembersPreferencesAndSetOptionsLogic';
import { defaultLogic409 } from './logicByCalls/409/defaultLogic409';
import { getTeamMembersAnomaliesLogic } from './logicByCalls/getTeamMembersAnomaliesLogic.js';
import { getTeamMembersHistoryLogic } from './logicByCalls/getTeamMembersHistoryLogic.js';
import { getExportDataLogic } from './logicByCalls/getExportDataLogic.js';
import { getTeamTimesheetDataByClientUUIDLogic } from './logicByCalls/getTeamTimesheetDataByClientUUIDLogic.js';

const routeUnauthorized = '/unauthorized';
const routeAllocations = '/allocations';
const routePortfolio = '/portfolio';
const routeBudget = '/budgets';
const routeTeamSetupGeneric = '/team/{teamData}/setup';

export const dynamicApiCall = async (datas) => {
  const { callName, navigate, params } = datas;

  return import(`../routes`)
    .then(async (module) => {
      const { [callName]: dynamicCallImport } = module;

      if (typeof dynamicCallImport === 'function') {
        const callData = await dynamicCallImport(params);

        switch (callData.status) {
          case 500:
            switch (callName) {
              case 'deleteTeamClientsByUUID':
                await deleteTeamClientsLogic500({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  routePortfolio,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putUserByUUID':
                await putUserByUUIDLogic500({
                  setUpdatingUserData: params.setUpdatingUserData,
                  updatingUserData: params.updatingUserData,
                  callData: callData.data,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenAddMemberModal: params.updateOpenAddMemberModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamClients':
                await putTeamClientsLogic500({
                  callData: callData.data,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamMembersAllocations':
                await putTeamMembersAllocationsLogic({
                  callData: callData.data,
                  reset: params.reset,
                  userView: params.userView,
                  view: params.view,
                  monthSelected: params.monthSelected,
                  yearSelected: params.yearSelected,
                  updateAllocationToUpdateData:
                    params.updateAllocationToUpdateData,
                  startWeekSelected: params.startWeekSelected,
                  endWeekSelected: params.endWeekSelected,
                  allocationDateStart: params.allocationDateStart,
                  fetchDataForWeek: params.fetchDataForWeek,
                  fetchDataForMonth: params.fetchDataForMonth,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              default:
                await defaultLogic500({
                  callData: callData.data,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenAddMemberModal: params.updateOpenAddMemberModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
            }
            break;
          case 403:
            switch (callName) {
              default:
                await defaultLogic403({
                  callData: callData.data,
                  navigate: params.navigate,
                  navigateTo,
                  routeAllocations,
                  routeUnauthorized,
                  currentRoute: params.currentRoute,
                  updateOpenModal:
                    params.updateOpenModal || params.updateOpenAddMemberModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
            }
            break;
          case 409:
            switch (callName) {
              default:
                await defaultLogic409({
                  callData: callData.data,
                  updateOpenModal:
                    params.updateOpenModal || params.updateOpenAddMemberModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
            }
            break;
          case 401:
            switch (callName) {
              case 'postLogin':
                await postLoginLogic401({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'getAuthTokenValidity':
                await getAuthTokenValidityLogic401({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                });
                break;
              case 'putPasswordByResetToken':
                await putPasswordByResetTokenLogic401({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putUserConfirmMail':
                await putUserConfirmMailLogic401({
                  routeAllocations,
                  navigate: params.navigate,
                  navigateTo,
                  setLoading: params.setLoading,
                  setError: params.setError,
                  setErrorMessage: params.setErrorMessage,
                  authToken: params.authToken,
                  callData: callData.data,
                  fetchPostNewConfirmationMailPromise:
                    params.fetchPostNewConfirmationMailPromise,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postNewConfirmationMail':
                await postNewConfirmationMailLogic401({
                  navigate: params.navigate,
                  navigateTo,
                  setErrorMessage: params.setErrorMessage,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  callData: callData.data,
                });
                break;
              case 'postPasswordByInvitedUser':
                await postPasswordByInvitedUserLogic401({
                  navigate: params.navigate,
                  navigateTo,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  callData: callData.data,
                });
                break;
              default:
                navigateTo({ navigate, routeName: routeUnauthorized });
                break;
            }
            break;
          case 303:
            switch (callName) {
              case 'postUserSignup':
                await postUserSignupLogic303({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postLogin':
                await postLoginLogic303({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postPasswordResetTokenByUserUUID':
                await postPasswordResetTokenByUserUUIDLogic303({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              default:
                break;
            }
            break;
          case 300:
            switch (callName) {
              case 'postUserSignup':
                await postUserSignupLogic300({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              default:
                break;
            }
            break;
          case 201:
            switch (callName) {
              case 'postLogin':
                await postLoginLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                });
                break;
              case 'postUserSignup':
                await postUserSignupLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postTeamTransferOwnership':
                await postTeamTransferOwnershipLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postStripePortalSession':
                await postStripePortalSessionLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  setStripePortalLink: params.setStripePortalLink,
                });
                break;
              case 'postStripeCheckoutSession':
                await postStripeCheckoutSessionLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                });
                break;
              case 'postBillingPlanByStripeCheckoutSessionID':
                await postBillingPlanByStripeCheckoutSessionIDLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  returnURI: params.returnURI,
                });
                break;
              case 'postPasswordResetTokenByUserUUID':
                await postPasswordResetTokenByUserUUIDLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  setIsButtonDisabled: params.setIsButtonDisabled,
                });
                break;
              case 'putPasswordByResetToken':
                await putPasswordByResetTokenLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postTeams':
                await postTeamsLogic({
                  callData: callData.data,
                  navigate,
                  navigateTo,
                  routeTeamSetupGeneric,
                });
                break;
              case 'postTeamBudgets':
                await postTeamBudgetsLogic({
                  fetchTeamBudgetsByTeamUUIDPromise:
                    params.fetchTeamBudgetsByTeamUUIDPromise,
                  teamUUID: params.teamUUID,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  reset: params.reset,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postTeamProjects':
                await postTeamProjectsLogic({
                  callData: callData.data,
                  updateOpenModal: params.updateOpenModal,
                  fetchTeamClientsByTeamUUIDPromise:
                    params.fetchTeamClientsByTeamUUIDPromise,
                  fetchTeamBudgetsByTeamUUIDPromise:
                    params.fetchTeamBudgetsByTeamUUIDPromise,
                  teamUUID: params.teamUUID,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  reset: params.reset,
                  setProjectUUID: params.setProjectUUID,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postInvitedUsersByMailAndTeamUUIDAndProjectUUID':
                await postInvitedUsersByMailAndTeamUUIDAndProjectUUIDLogic({
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postInvitedUsersByMailAndTeamUUID':
                await postInvitedUsersByMailAndTeamUUIDLogic({
                  fetchMembersScreenData: params.fetchMembersScreenData,
                  callData: callData.data,
                  reset: params.reset,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  updateOpenMemberSlideOver: params.updateOpenMemberSlideOver,
                });
                break;
              case 'putTeamMembersByUUID':
                return putTeamMembersByUUIDLogic({
                  callData: callData.data,
                  fetchInfo: params.fetchInfo,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
              case 'putConfirmTeamTransferOwnership':
                await putConfirmTeamTransferOwnershipLogic({
                  navigate,
                  navigateTo,
                  setUpdatingUserData: params.setUpdatingUserData,
                  updatingUserData: params.updatingUserData,
                  fetchInfo: params.fetchInfo,
                  callData: callData.data,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putUserByUUID':
                await putUserByUUIDLogic({
                  setUpdatingUserData: params.setUpdatingUserData,
                  updatingUserData: params.updatingUserData,
                  fetchInfo: params.fetchInfo,
                  callData: callData.data,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postTeamClients':
                postTeamClientsLogic({
                  fetchTeamClientsByTeamUUIDPromise:
                    params.fetchTeamClientsByTeamUUIDPromise,
                  fetchTeamBudgetsByTeamUUIDPromise:
                    params.fetchTeamBudgetsByTeamUUIDPromise,
                  teamUUID: params.teamUUID,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  reset: params.reset,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamClients':
                await putTeamClientsLogic({
                  callData: callData.data,
                  navigate: params.navigate,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  name: params.name,
                  fetchTeamClientsByNamePromise:
                    params.fetchTeamClientsByNamePromise,
                  reset: params.reset,
                  resetValues: {
                    clientName: params.name,
                    clientDescription: params.description,
                    clientNotes: params.notes,
                    clientColor: params.color,
                  },
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamBudgets':
                await putTeamBudgetsLogic({
                  callData: callData.data,
                  navigate: params.navigate,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  name: params.name,
                  fetchTeamBudgetsByNamePromise:
                    params.fetchTeamBudgetsByNamePromise,
                  reset: params.reset,
                  resetValues: {
                    budgetName: params.name,
                    budgetDescription: params.description,
                    budgetNotes: params.notes,
                    budgetAmount: params.amount,
                    budgetStartedAt: params.startedAt,
                    budgetEndedAt: params.endedAt,
                  },
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamProjectsByUUID':
                await putTeamProjectsByUUIDLogic({
                  projectName: params.name,
                  callData: callData.data,
                  clientName: params.clientName,
                  navigate: params.navigate,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  reset: params.reset,
                  resetValues: {
                    projectName: params.name,
                    projectDescription: params.description,
                    projectNotes: params.notes,
                    projectColor: params.color,
                    projectMembers: params.teamMembersUUIDs,
                    projectTags: params.tagsUUIDs,
                  },
                  fetchTeamProjectsPromise: params.fetchTeamProjectsPromise,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'deleteTeamClientsByUUID':
                await deleteTeamClientsLogic({
                  navigate,
                  navigateTo,
                  updateOpenModal: params.updateOpenModal,
                  routePortfolio,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'deleteTeamBudgetsByUUID':
                await deleteTeamBudgetsByUUIDLogic({
                  navigate,
                  navigateTo,
                  updateOpenModal: params.updateOpenModal,
                  routeBudget,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'deleteTeamTagsByUUID':
                await deleteTeamTagsByUUIDLogic({
                  navigate,
                  navigateTo,
                  updateOpenModal: params.updateOpenModal,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  teamUUID: params.teamUUID,
                  fetchTeamTagsByTeamUUIDPromise:
                    params.fetchTeamTagsByTeamUUIDPromise,
                });
                break;
              case 'deleteTeamProjectsByUUID':
                await deleteTeamProjectsByUUID({
                  navigate,
                  navigateTo,
                  updateOpenModal: params.updateOpenModal,
                  routePortfolio,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postTeamMembersAllocations':
                await postTeamMembersAllocationsLogic({
                  callData: callData.data,
                  reset: params.reset,
                  view: params.view,
                  teamUUID: params.teamUUID,
                  monthSelected: params.monthSelected,
                  yearSelected: params.yearSelected,
                  fetchAnomalies: params.fetchAnomalies,
                  startWeekSelected: params.startWeekSelected,
                  endWeekSelected: params.endWeekSelected,
                  fetchDataForWeek: params.fetchDataForWeek,
                  fetchDataForMonth: params.fetchDataForMonth,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamMembersAllocations':
                const result = await putTeamMembersAllocationsLogic({
                  callData: callData.data,
                  returnData: params.returnData,
                  reset: params.reset,
                  view: params.view,
                  teamUUID: params.teamUUID,
                  fetchAnomalies: params.fetchAnomalies,
                  updateAllocationToUpdateData:
                    params.updateAllocationToUpdateData,
                  fetchFiltersData: params.fetchFiltersData,
                  monthSelected: params.monthSelected,
                  yearSelected: params.yearSelected,
                  startWeekSelected: params.startWeekSelected,
                  endWeekSelected: params.endWeekSelected,
                  fetchDataForWeek: params.fetchDataForWeek,
                  fetchDataForMonth: params.fetchDataForMonth,
                  updateOpenSlideOver: params.updateOpenSlideOver,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                return result;
              case 'putTeamTagsByUUID':
                await putTeamTagsByUUIDLogic({
                  callData: callData.data,
                  teamUUID: params.teamUUID,
                  updateOpenModal: params.updateOpenModal,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  fetchTeamTagsByTeamUUIDPromise:
                    params.fetchTeamTagsByTeamUUIDPromise,
                });
                break;
              case 'postTeamTags':
                await postTeamTagsLogic({
                  callData: callData.data,
                  fetchTeamTagsByTeamUUIDPromise:
                    params.fetchTeamTagsByTeamUUIDPromise,
                  teamUUID: params.teamUUID,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'deleteTeamMembersByUUID':
                await deleteTeamMembersByUUIDLogic({
                  callData: callData.data,
                  fetchMembersScreenData: params.fetchMembersScreenData,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'putTeamsByUUID':
                await putTeamsByUUIDLogic({
                  callData: callData.data,
                  navigate: params.navigate,
                  path: params.path,
                  fetchTeamsByUUIDPromise: params.fetchTeamsByUUIDPromise,
                  fetchTimezonePromise: params.fetchTimezonePromise,
                  fetchCountryPromise: params.fetchCountryPromise,
                  fetchCurrencyPromise: params.fetchCurrencyPromise,
                  updateTeamName: params.updateTeamName,
                  teamName: params.teamName,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'deleteTeamsByUUID':
                await deleteTeamsByUUIDLogic({
                  navigateTo,
                  routeAllocations,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  navigate,
                });
                break;
              case 'putUserConfirmMail':
                await putUserConfirmMailLogic({
                  navigate: params.navigate,
                  navigateTo,
                  routeAllocations,
                  setLoading: params.setLoading,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postNewConfirmationMail':
                await postNewConfirmationMailLogic({
                  navigate: params.navigate,
                  callData: callData.data,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              case 'postPasswordByInvitedUser':
                await postPasswordByInvitedUserLogic({
                  navigate: params.navigate,
                  navigateTo,
                  routeAllocations,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                  callData: callData.data,
                });
                break;
              default:
                break;
            }
            break;
          case 200:
            switch (callName) {
              case 'getAuthTokenValidity':
                getAuthTokenValidityLogic({
                  navigate,
                  navigateTo,
                  callData: callData.data,
                });
                break;
              case 'getBillingPlanByTeamUUID':
                await getBillingPlanByTeamUUIDLogic({
                  callData: callData.data,
                  setTeamPlan: params.setTeamPlan,
                  postStripePortalSessionPromise:
                    params.postStripePortalSessionPromise || null,
                  teamUUID: params.teamUUID,
                  navigate,
                  navigateTo,
                });
                break;
              case 'getTeamsByOwnerUUID':
                await getTeamsByOwnerUUIDLogic({
                  callData: callData.data,
                  teamUUID: params.teamUUID,
                  navigate,
                  navigateTo,
                  routeUnauthorized,
                });
                break;
              case 'getTeamMembersByAuthToken':
                return callData.data;
              case 'getUsersByAuthToken':
                await getUsersByAuthTokenLogic({
                  callData: callData.data[0],
                  setUserData: params.setUserData,
                  setUpdatingUserData: params.setUpdatingUserData,
                });
                break;
              case 'getTeamMembersByTeamUUID':
                const data = await getTeamMembersByTeamUUIDLogic({
                  callData: callData.data,
                  setPeople: params.setPeople,
                  rawData: params.rawData,
                  returnData: params.returnData,
                  setProjectMembers: params.setProjectMembers,
                });
                return data;
              case 'getTeamMembersPreferencesAndSetOptions':
                await getTeamMembersPreferencesAndSetOptionsLogic({
                  callData: callData.data,
                  setTimeLabel: params.setTimeLabel,
                  setCreateUpdateValue: params.setCreateUpdateValue,
                  setOptions: params.setOptions,
                });
                break;
              case 'getTeamTimesheetDataByClientUUID':
                await getTeamTimesheetDataByClientUUIDLogic({
                  callData: callData.data,
                  clientUUID: params.clientUUID,
                  startedAt: params.startedAt,
                  endedAt: params.endedAt,
                  setTimesheetDataSelectionResult:
                    params.setTimesheetDataSelectionResult,
                  returnData: params.returnData,
                });
                break;
              case 'getPrivileges':
                await getPrivilegesLogic({
                  callData: callData.data,
                  setPrivileges: params.setPrivileges,
                  names: params.names,
                });
                break;
              case 'getContractsByAuthToken':
                await getContractsByAuthTokenLogic({
                  callData: callData.data,
                  setContracts: params.setContracts,
                });
                break;
              case 'getTeamClientsByTeamUUID':
                await getTeamClientsByTeamUUIDLogic({
                  callData: callData.data,
                  setClients: params.setClients,
                  setSearchedClientsResult: params.setSearchedClientsResult,
                  setSearchedProjectsResult: params.setSearchedProjectsResult,
                });
                break;
              case 'getTeamBudgetsByTeamUUID':
                await getTeamBudgetsByTeamUUIDLogic({
                  callData: callData.data,
                  setBudgets: params.setBudgets,
                  setSearchedBudgetsResult: params.setSearchedBudgetsResult,
                });
                break;
              case 'getTeamProjectsByTeamClientUUID':
                await getTeamProjectsByTeamClientUUIDLogic({
                  callData: callData.data,
                  setProjects: params.setProjects,
                  setSearchedProjectsResult: params.setSearchedProjectsResult,
                });
                break;
              case 'getTeamTagsByTeamUUID':
                await getTeamTagsByTeamUUIDLogic({
                  callData: callData.data,
                  setListOfTags: params.setListOfTags,
                });
                break;
              case 'getTeamProject':
                await getTeamProjectLogic({
                  callData: callData.data,
                  setProject: params.setProject,
                });
                break;
              case 'getTeamMembersAllocationsByTeamAndProjectUUID':
                await getTeamMembersAllocationsByTeamAndProjectUUIDLogic({
                  callData: callData.data,
                  setActivities: params.setActivities,
                });
                break;
              case 'getTeamProjectsMembersByProjectUUID':
                await getTeamProjectsMembersByProjectUUIDLogic({
                  callData: callData.data,
                  setMembersAuthorizedToCharge:
                    params.setMembersAuthorizedToCharge,
                });
                break;
              case 'getTeamProjectsByTeamUUID':
                await getTeamProjectsByTeamUUIDLogic({
                  callData: callData.data,
                  setProjects: params.setProjects,
                });
                break;
              case 'getTeamProjectsMembersByTeamAndMemberUUID':
                await getTeamProjectsMembersByTeamAndMemberUUIDLogic({
                  callData: callData.data,
                  setProjectsAuthorizedToCharge:
                    params.setProjectsAuthorizedToCharge,
                });
                break;
              case 'getTeamMembersAllocations':
                const result = await getTeamMembersAllocationsLogic({
                  setDays: params.setDays,
                  callData: callData.data,
                  returnData: params.returnData,
                });
                return result;
              case 'getTeamMembersAnomalies':
                await getTeamMembersAnomaliesLogic({
                  setAnomalies: params.setAnomalies,
                  callData: callData.data,
                  setLoadingAnomalies: params.setLoadingAnomalies,
                });
                break;
              case 'getTeamMembersHistory':
                await getTeamMembersHistoryLogic({
                  setHistory: params.setHistory,
                  callData: callData.data,
                  setLoadingHistory: params.setLoadingHistory,
                });
                break;
              case 'getTeamProjectsTags':
                await getTeamProjectsTagsLogic({
                  callData: callData.data,
                  setTags: params.setTags,
                });
                break;
              case 'getTeamMembersAllocationsFilters':
                await getTeamMembersAllocationsFiltersLogic({
                  callData: callData.data,
                  setFiltersData: params.setFiltersData,
                });
                break;
              case 'getTeamProjectsMilesstones':
                await getTeamProjectsMilesstonesLogic({
                  callData: callData.data,
                  setMilestones: params.setMilestones,
                });
                break;
              case 'getTimezone':
                await getTimezoneLogic({
                  callData: callData.data,
                  setTimezones: params.setTimezones,
                });
                break;
              case 'getCountry':
                await getCountryLogic({
                  callData: callData.data,
                  setCountries: params.setCountries,
                });
                break;
              case 'getCountryByTeamUUID':
                await getCountryByTeamUUIDLogic({
                  callData: callData.data,
                  setHolidays: params.setHolidays,
                });
                break;
              case 'getCurrency':
                await getCurrencyLogic({
                  callData: callData.data,
                  setCurrencies: params.setCurrencies,
                });
                break;
              case 'getTeamsByUUID':
                await getTeamsByUUIDLogic({
                  callData: callData.data,
                  setCurrentTeam: params.setCurrentTeam,
                  setCheckboxesOptions: params.setCheckboxesOptions,
                  setCurrency: params.setCurrency,
                  checkboxesOptions: params.checkboxesOptions,
                });
                break;
              case 'getTeamClients':
                await getTeamClientsLogic({
                  callData: callData.data,
                  setClient: params.setClient,
                  clientUUID: params.clientUUID,
                });
                break;
              case 'getTeamBudgets':
                await getTeamBudgetsLogic({
                  callData: callData.data,
                  setBudget: params.setBudget,
                  budgetUUID: params.budgetUUID,
                });
                break;
              case 'getAccessPage':
                await getAccessPageLogic({
                  callData: callData.data,
                  setUserPrivileges: params.setUserPrivileges,
                });
                break;
              case 'getExportData':
                await getExportDataLogic({
                  callData: callData.data,
                  format: params.format,
                  updateOpenNotification: params.updateOpenNotification,
                  updateNotificationData: params.updateNotificationData,
                });
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      } else {
        // TODO: Gestion d'erreur (404)
        console.error(`Function '${callName}' not found in module`);
      }
    })
    .catch((error) => {
      // TODO: Gestion d'erreur (500)
      console.error('Error:', error);
    });
};
