import { Page, Document, StyleSheet, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

// Define styles for the PDF document
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 32,
    backgroundColor: '#f8f9fa',
    fontFamily: 'Helvetica',
  },
  header: {
    marginBottom: 10,
  },
  title: {
    fontSize: 18,
    textAlign: 'left',
    fontFamily: 'Helvetica-Bold',
    color: '#111827',
    marginBottom: 8,
  },
  subTitle: {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'Helvetica-Bold',
    color: '#1f2937',
    marginBottom: 4,
  },
  dateText: {
    fontSize: 8,
    color: '#6b7280',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  secondaryText: {
    fontSize: 8,
    color: '#6b7280',
  },
  projectTitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold',
    marginTop: 10,
    color: '#1f2937',
  },
  noAllocationsText: {
    fontSize: 8,
    color: '#6b7280',
    marginTop: 4,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#6b7280',
    borderBottomStyle: 'solid',
    marginTop: 4,
    paddingBottom: 4,
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 2,
  },
  smallCell: {
    fontSize: 8,
    color: '#1f2937',
  },
  rowEven: {
    backgroundColor: '#f0f0f0',
  },
  totalRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#6b7280',
    borderTopStyle: 'solid',
    marginTop: 4,
    paddingTop: 4,
  },
  totalText: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    color: '#1f2937',
  },
  footer: {
    fontSize: 8,
    color: '#6b7280',
    textAlign: 'center',
    position: 'absolute',
    bottom: 20, // Adjust for space from the bottom
    left: 32,
    right: 32,
  },
});

// Determine cell width dynamically
const getDynamicCellStyle = (columns) => ({
  width: `${100 / columns}%`,
});

// Format numbers without unnecessary trailing zeros
const formatNumber = (value) => parseFloat(value).toString();

const TimesheetPDFTemplate = ({ data }) => {
  const selectedProjects = data?.timesheetDataSelectionResult?.projects
    .filter((project) =>
      data?.timesheetDataSelection.some(
        (selection) => selection.parentUUID === project.uuid
      )
    )
    .map((project) => {
      const selection = data?.timesheetDataSelection.find(
        (item) => item.parentUUID === project.uuid
      );
      const allowedMemberUUIDs = selection ? selection.childrenUUID : [];

      const allocationsWithRates = project.allocations
        .filter((allocation) =>
          allowedMemberUUIDs.includes(allocation.teamMemberUUID)
        )
        .map((allocation) => {
          const member = project.members.find(
            (member) => member.uuid === allocation.teamMemberUUID
          );
          const hourlyRate =
            member?.hourlyRate || member?.teamDefaultHourlyRate || 0;

          return {
            ...allocation,
            hourlyRate,
            calculatedAmount: (allocation.allocationHours * hourlyRate).toFixed(
              2
            ),
          };
        });

      return { ...project, allocations: allocationsWithRates };
    });

  const currentDate = format(new Date(), 'dd MMMM yyyy');
  const showManDayColumn =
    data.shouldDisplayAllocationsHoursInManDay && data.hoursInManDayRate;
  const hasClient = Boolean(data?.timesheetDataSelectionResult?.clientName);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.dateText}>{currentDate}</Text>
          <Text style={styles.title}>Timesheet</Text>

          {data.from && data.to && (
            <Text style={styles.secondaryText}>
              Period : {format(new Date(data.from), 'dd MMMM yyyy')} to{' '}
              {format(new Date(data.to), 'dd MMMM yyyy')}
            </Text>
          )}

          {hasClient && (
            <Text style={styles.secondaryText}>
              Client : {data.timesheetDataSelectionResult.clientName}
            </Text>
          )}
        </View>

        {hasClient && (
          <>
            <Text style={styles.subTitle}>Details</Text>

            {selectedProjects?.length > 0 ? (
              selectedProjects.map((project) => {
                const totalHours = project.allocations.reduce(
                  (sum, allocation) => sum + allocation.allocationHours,
                  0
                );
                const totalManDays = showManDayColumn
                  ? project.allocations.reduce(
                      (sum, allocation) =>
                        sum +
                        allocation.allocationHours / data.hoursInManDayRate,
                      0
                    )
                  : 0;
                const totalAmount = data.shouldDisplayAllocationsHoursInCurrency
                  ? project.allocations.reduce(
                      (sum, allocation) =>
                        sum + parseFloat(allocation.calculatedAmount),
                      0
                    )
                  : 0;

                const columnsCount =
                  2 + // Date, Member columns
                  (data.shouldDisplayAllocationsTags ? 1 : 0) +
                  1 + // Hours column
                  (showManDayColumn ? 1 : 0) +
                  (data.shouldDisplayAllocationsHoursInCurrency ? 2 : 0);

                const dynamicCellStyle = getDynamicCellStyle(columnsCount);

                return (
                  <View key={project.uuid}>
                    <Text style={styles.projectTitle}>{project.name}</Text>

                    {project.allocations.length > 0 ? (
                      <View>
                        <View style={styles.tableHeader}>
                          <Text style={[styles.smallCell, dynamicCellStyle]}>
                            Date
                          </Text>
                          <Text style={[styles.smallCell, dynamicCellStyle]}>
                            Member
                          </Text>
                          {data.shouldDisplayAllocationsTags && (
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              Tag
                            </Text>
                          )}
                          <Text style={[styles.smallCell, dynamicCellStyle]}>
                            Hours
                          </Text>
                          {showManDayColumn && (
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              Man Days
                            </Text>
                          )}
                          {data.shouldDisplayAllocationsHoursInCurrency && (
                            <>
                              <Text
                                style={[styles.smallCell, dynamicCellStyle]}
                              >
                                Hourly rate
                              </Text>
                              <Text
                                style={[styles.smallCell, dynamicCellStyle]}
                              >
                                Amount
                              </Text>
                            </>
                          )}
                        </View>

                        {project.allocations.map((allocation, index) => (
                          <View
                            style={[
                              styles.tableRow,
                              index % 2 === 1 && styles.rowEven,
                            ]}
                            key={allocation.uuid}
                          >
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              {format(
                                new Date(allocation.allocationDate),
                                'dd MMM yyyy'
                              )}
                            </Text>
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              {allocation.firstname} {allocation.lastname}
                            </Text>
                            {data.shouldDisplayAllocationsTags && (
                              <Text
                                style={[styles.smallCell, dynamicCellStyle]}
                              >
                                {allocation.tagName || ''}
                              </Text>
                            )}
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              {formatNumber(allocation.allocationHours)}
                            </Text>
                            {showManDayColumn && (
                              <Text
                                style={[styles.smallCell, dynamicCellStyle]}
                              >
                                {formatNumber(
                                  (
                                    allocation.allocationHours /
                                    data.hoursInManDayRate
                                  ).toFixed(2)
                                )}
                              </Text>
                            )}
                            {data.shouldDisplayAllocationsHoursInCurrency && (
                              <>
                                <Text
                                  style={[styles.smallCell, dynamicCellStyle]}
                                >
                                  {data?.teamCurrencySymbol}{' '}
                                  {formatNumber(allocation.hourlyRate)}
                                </Text>
                                <Text
                                  style={[styles.smallCell, dynamicCellStyle]}
                                >
                                  {data?.teamCurrencySymbol}{' '}
                                  {formatNumber(allocation.calculatedAmount)}
                                </Text>
                              </>
                            )}
                          </View>
                        ))}

                        <View style={styles.totalRow}>
                          <Text style={[styles.smallCell, dynamicCellStyle]}>
                            <Text style={styles.totalText}>Total</Text>
                          </Text>
                          <Text style={[styles.smallCell, dynamicCellStyle]} />
                          {data.shouldDisplayAllocationsTags && (
                            <Text
                              style={[styles.smallCell, dynamicCellStyle]}
                            />
                          )}
                          <Text style={[styles.smallCell, dynamicCellStyle]}>
                            {formatNumber(totalHours)}
                          </Text>
                          {showManDayColumn && (
                            <Text style={[styles.smallCell, dynamicCellStyle]}>
                              {formatNumber(totalManDays.toFixed(2))}
                            </Text>
                          )}
                          {data.shouldDisplayAllocationsHoursInCurrency && (
                            <>
                              <Text
                                style={[styles.smallCell, dynamicCellStyle]}
                              />
                              <Text
                                style={[
                                  styles.smallCell,
                                  dynamicCellStyle,
                                  styles.totalText,
                                ]}
                              >
                                {data?.teamCurrencySymbol}{' '}
                                {formatNumber(totalAmount.toFixed(2))}
                              </Text>
                            </>
                          )}
                        </View>
                      </View>
                    ) : (
                      <Text style={styles.noAllocationsText}>
                        No allocations data
                      </Text>
                    )}
                  </View>
                );
              })
            ) : (
              <Text style={styles.noAllocationsText}>No project to show</Text>
            )}
          </>
        )}

        {data.timesheetFooter && (
          <Text style={styles.footer}>{data.timesheetFooter}</Text>
        )}
      </Page>
    </Document>
  );
};

export default TimesheetPDFTemplate;
