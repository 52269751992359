import React, { useState, useEffect } from 'react';
import { Switch } from '@headlessui/react';
import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { formatCurrency } from '../../services/utils/formatCurrency';
import { getContrastedColor } from '../../services/utils/colorPicker';

export const AmountConsumptionChart = ({
  label,
  maxAmount,
  currencySymbol = null,
  data = [],
  selectedOption = 'projects',
  onSelectOption = () => {},
  dropdownOptions = [],
}) => {
  const [colorAssignments, setColorAssignments] = useState([]);
  const [tooltipText, setTooltipText] = useState('');
  const [showAsPercentage, setShowAsPercentage] = useState(false);

  useEffect(() => {
    let previousGroup = null;
    const newUsedColors = [];
    const newColorAssignments = data.map((item, index) => {
      const { color, groupKey } = getContrastedColor(
        index,
        previousGroup,
        newUsedColors
      );
      previousGroup = groupKey;
      newUsedColors.push(color);
      return color;
    });

    setColorAssignments(newColorAssignments);
  }, [data]);

  // Calculate the total amount consumed
  const totalConsumed = data.reduce((sum, item) => {
    const amount = parseFloat(item.amount) || 0;
    return sum + amount;
  }, 0);

  // Calculate the position of the max amount indicator
  const maxAmountPosition =
    totalConsumed > maxAmount ? (maxAmount / totalConsumed) * 100 : 100;

  // Calculate the remaining percentage of the budget
  const remainingPercentage =
    totalConsumed < maxAmount
      ? ((maxAmount - totalConsumed) / maxAmount) * 100
      : 0;

  // Filter out items with 0 amount
  const filteredData = data.filter((item) => item.amount > 0);

  const handleMouseEnter = (text) => {
    setTooltipText(text);
  };

  const handleMouseLeave = () => {
    setTooltipText('');
  };

  return (
    <div className="relative w-full rounded-lg border px-4 py-4 bg-white">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <p className="text-primary">{label}</p>

          {/* Dropdown for selecting projects or clients */}
          <div className="flex relative ml-1">
            <select
              value={selectedOption}
              onChange={(e) => onSelectOption(e.target.value)}
              className="cursor-pointer custom-select appearance-none p-0 pr-7 text-sm font-semibold text-indigo-500 border-none bg-transparent focus:outline-none focus:ring-0"
              style={{
                backgroundImage:
                  'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 20 20%27%3e%3cpath stroke=%27%236366f1%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%271.5%27 d=%27M6 8l4 4 4-4%27/%3e%3c/svg%3e")',
              }}
            >
              {dropdownOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex items-center">
          <p
            className={`text-sm font-normal ${
              totalConsumed > maxAmount
                ? 'text-red-600 font-semibold'
                : 'text-gray-600'
            }`}
          >
            {showAsPercentage
              ? `${((totalConsumed / maxAmount) * 100).toFixed(2)}%`
              : `${formatCurrency(totalConsumed)} ${currencySymbol}`}
          </p>
          <p className="text-sm font-light text-gray-600 ml-1">
            /{' '}
            {showAsPercentage
              ? '100%'
              : `${formatCurrency(maxAmount)} ${currencySymbol}`}
          </p>

          {/* Toggle between currency and percentage */}
          <Switch
            checked={showAsPercentage}
            onChange={setShowAsPercentage}
            className={`${
              showAsPercentage ? 'bg-indigo-600' : 'bg-gray-200'
            } group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ml-4`}
          >
            <span className="sr-only">
              Toggle between currency and percentage
            </span>
            <span
              className={`${
                showAsPercentage ? 'translate-x-5' : 'translate-x-0'
              } pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
            >
              <span
                aria-hidden="true"
                className={`${
                  showAsPercentage
                    ? 'opacity-0 duration-100 ease-out'
                    : 'opacity-100 duration-200 ease-in'
                } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
              >
                <span className="text-gray-400 text-xs font-semibold">%</span>
              </span>
              <span
                aria-hidden="true"
                className={`${
                  showAsPercentage
                    ? 'opacity-100 duration-200 ease-in'
                    : 'opacity-0 duration-100 ease-out'
                } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
              >
                <span className="text-indigo-600 text-xs font-semibold">%</span>
              </span>
            </span>
          </Switch>
        </div>
      </div>

      <div className="mt-2 relative">
        <div className="flex h-8 rounded bg-slate-200 overflow-hidden relative">
          {filteredData.map((item, index) => {
            const color = colorAssignments[index];
            return (
              <div
                key={index}
                className={`cursor-help h-full ${color} transition-opacity duration-200 ease-in-out hover:opacity-70`}
                style={{
                  width: `${(item.amount / maxAmount) * 100}%`, // Proportionate to maxAmount
                }}
                onMouseEnter={() =>
                  handleMouseEnter(
                    `${item.label}: ${
                      showAsPercentage
                        ? `${((item.amount / maxAmount) * 100).toFixed(2)}%`
                        : `${formatCurrency(item.amount)} ${currencySymbol}`
                    }`
                  )
                }
                onMouseLeave={handleMouseLeave}
              />
            );
          })}

          {/* Render the remaining budget as a gray bar if there is any left */}
          {remainingPercentage > 0 && (
            <div
              className="cursor-help h-full bg-gray-300 transition-opacity duration-200 ease-in-out hover:opacity-70"
              style={{ width: `${remainingPercentage}%` }}
              onMouseEnter={() =>
                handleMouseEnter(
                  `Remaining Budget: ${
                    showAsPercentage
                      ? `${remainingPercentage.toFixed(2)}%`
                      : `${formatCurrency(
                          maxAmount - totalConsumed
                        )} ${currencySymbol}`
                  }`
                )
              }
              onMouseLeave={handleMouseLeave}
            />
          )}

          {/* Conditionally render the Max Amount Indicator Dotted Line and Label */}
          {totalConsumed > maxAmount && (
            <div
              className="absolute top-0 h-full border-l border-black border-dashed"
              style={{ left: `${maxAmountPosition}%` }}
            >
              {/* Label on top of the dotted line */}
              <div
                className="absolute bg-black text-white font-extralight text-xs px-2 rounded-bl"
                style={{
                  whiteSpace: 'nowrap',
                  transform: 'translateX(-100%)',
                  left: '0',
                }}
              >
                {showAsPercentage
                  ? `100%`
                  : `${formatCurrency(maxAmount)} ${currencySymbol}`}
              </div>
            </div>
          )}
        </div>

        {/* Tooltip */}
        {tooltipText && (
          <div className="absolute mt-1 bg-black text-white text-xs px-2 py-1 rounded pointer-events-none">
            {tooltipText}
          </div>
        )}
      </div>

      {/* Legend with colored dots and project names */}
      <div className="flex flex-wrap mt-2">
        {filteredData.map((item, index) => (
          <div key={index} className="flex items-center mr-2">
            {/* Colored dot */}
            <span
              className={`inline-block w-2 h-2 mr-1 rounded-full ${colorAssignments[index]}`}
            />
            {/* Item name */}
            <span className="font-normal text-xs">{item.label}</span>
          </div>
        ))}

        {/* Add legend for the budget amount (gray bar) */}
        <div className="flex items-center">
          <span className="inline-block w-2 h-2 mr-1 rounded-full bg-gray-300" />
          <span className="font-normal text-xs">Available</span>
        </div>
      </div>
    </div>
  );
};
