import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import {
  Bars3Icon,
  ChartPieIcon,
  FolderIcon,
  UsersIcon,
  XMarkIcon,
  PencilIcon,
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
  KeyIcon,
  QuestionMarkCircleIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Link, useNavigate } from 'react-router-dom';
import { driver } from 'driver.js';
import { dynamicApiCall } from '../../services/api/response/callResponsehandler';
import { PulseLoader } from '../atoms/PulseLoader';
import { useTeam } from '../../contexts/TeamContext';
import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';
import { Modal } from '../molecules/Modal';
import { AddMemberPopupHeader } from '../molecules/AddMemberPopupHeader';
import { ModalHandleButtons } from '../molecules/ModalHandleButtons';
import { TabsWithUnderline } from '../molecules/TabsWithUnderline';
import { LabelInput } from '../atoms/LabelInput';
import { NotificationContext } from '../../contexts/NotificationContext';
import LogoSvg from '../atoms/LogoSvg';

import 'driver.js/dist/driver.css';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const driverObj = driver({
  showProgress: true,
  steps: [
    {
      element: '#yourTeams',
      popover: {
        title: `Welcome !`,
        description: `This section allows you to select teams, create new one and manage settings (if you're owner).`,
        position: 'right',
      },
    },
    {
      element: '#menuPortfolio',
      popover: {
        title: `Your portfolio !`,
        description: `Click on your clients & projects to see details of time spent on it (aka. "Allocations").`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#menuLinkPortfolio').click();
      },
    },
    {
      element: '#menuAllocations',
      popover: {
        title: `Most important part !`,
        description: `Allocations menu is basically the reality of the times everyone spent on a project ! There is 3 submenus`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#menuLinkAllocations').click();
      },
    },
    {
      element: '#allocationsTabCalendar',
      popover: {
        title: `Calendar`,
        description: `Just click on a cell to allocate your time. 100% should be logged for each worked day.`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabCalendar').click();
      },
    },
    {
      element: '#allocationsTabAnomalies',
      popover: {
        title: `Anomalies`,
        description: `No worries, we'll bring you anomalies that you can fix if you forgot one or if it's uncomplete !`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabAnomalies').click();
      },
    },
    {
      element: '#allocationsTabHistory',
      popover: {
        title: `History`,
        description: `If you need, the history of your loogged time !`,
        position: 'right',
      },
      onHighlighted: () => {
        document.querySelector('#allocationsTabHistory').click();
      },
    },
  ],
});

export const SideBarLayout = ({ children }) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [modalTabs, setModalTabs] = useState([
    { name: 'Reset password', current: true },
  ]);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [userData, setUserData] = useState();
  const [updatingUserData, setUpdatingUserData] = useState({});
  const [shouldRestartGuidedTour, setShouldRestartGuidedTour] = useState(false);

  const updateModalTabs = (name) => {
    const updatedTabs = modalTabs.map((tab) => ({
      ...tab,
      current: tab.name === name,
    }));
    setModalTabs(updatedTabs);
  };

  const updateOpenModal = (newOpenModal) => {
    setOpenModal(newOpenModal);
  };

  useEffect(() => {
    // Combine the conditions to check if the guide should start or restart
    const guideAlreadyStarted = localStorage.getItem('guideStarted') === 'true';

    const authToken = localStorage.getItem('authToken');

    if ((!guideAlreadyStarted || shouldRestartGuidedTour) && authToken) {
      // Start or restart the guided tour
      driverObj.drive();
      localStorage.setItem('guideStarted', 'true'); // Mark the guide as started
      setShouldRestartGuidedTour(false); // Reset the flag for restarting the guide
    }
  }, [shouldRestartGuidedTour]); // Only re-run the effect if shouldRestartGuidedTour changes

  const navigation = [
    {
      name: 'Allocations',
      href: '/allocations',
      icon: PencilIcon,
      current: window.location.pathname.includes('/allocations'),
    },
    {
      name: 'Portfolio',
      href: '/portfolio',
      icon: FolderIcon,
      current: window.location.pathname.includes('/portfolio'),
    },
    {
      name: 'Budgets',
      href: '/budgets',
      icon: BanknotesIcon,
      current: window.location.pathname.includes('/budgets'),
    },
    {
      name: 'Members',
      href: '/members',
      icon: UsersIcon,
      current: window.location.pathname.includes('/members'),
    },
  ];

  /* Access context values using the useTeam hook */
  const {
    updateTeamUUID,
    teamUUID,
    teamName,
    updateTeamName,
    updateTeamCurrencyUUID,
    updateTeamCurrencySymbol,
    updateTeamCurrencyName,
    updateTeamDefaultHoursPreferences,
    updateTeamDefaultHourlyRate,
    updateTeamDefaultDailyRate,
  } = useTeam();

  /* Access context notifs */
  const { updateOpenNotification, updateNotificationData } =
    useContext(NotificationContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* BEGINNING CALL API */
  /* Call to update user infos */
  const fetchPutUserByUUIDData = async () => {
    /* Check if passwords match */
    if (
      updatingUserData.newPassword &&
      updatingUserData.newPasswordConfirm &&
      updatingUserData.newPassword !== updatingUserData.newPasswordConfirm
    ) {
      setPasswordsMatch(false);
      return; /* Do not proceed with the API call */
    }

    await dynamicApiCall({
      callName: 'putUserByUUID',
      navigate,
      params: {
        setUpdatingUserData,
        updatingUserData,
        firstName: updatingUserData.firstName,
        lastName: updatingUserData.lastName,
        mail: updatingUserData.mail,
        currentPassword: updatingUserData.currentPassword || undefined,
        newPassword: updatingUserData.newPassword || undefined,
        fetchInfo: getUserInfoAndTeam,
        updateOpenModal,
        updateOpenNotification,
        updateNotificationData,
      },
    });
  };

  /* Get team user is associated to */
  const fetchTeamMembersByUsersUUIDPromise = async () => {
    const getTeamMembersByAuthTokenData = await dynamicApiCall({
      callName: 'getTeamMembersByAuthToken',
    });

    if (getTeamMembersByAuthTokenData) {
      const teamUUIDFromParams = localStorage.getItem('tmx') || teamUUID;

      getTeamMembersByAuthTokenData.forEach((team, index) => {
        team.initial = team.name.charAt(0).toUpperCase();
        if (index === 0 && teamUUIDFromParams === null) {
          team.isSelected = true;
          updateTeamUUID(team.uuid);
          updateTeamName(team.name);
          updateTeamCurrencyUUID(team.currencyUUID);
          updateTeamCurrencySymbol(team.currencySymbol);
          updateTeamCurrencyName(team.currencyName);
          updateTeamDefaultHoursPreferences(team.defaultHoursPreferences);
          updateTeamDefaultHourlyRate(team.defaultHourlyRate);
          updateTeamDefaultDailyRate(team.defaultDailyRate);
        } else if (teamUUIDFromParams === team.uuid) {
          team.isSelected = true;
          updateTeamUUID(team.uuid);
          updateTeamName(team.name);
          updateTeamCurrencyUUID(team.currencyUUID);
          updateTeamCurrencySymbol(team.currencySymbol);
          updateTeamCurrencyName(team.currencyName);
          updateTeamDefaultHoursPreferences(team.defaultHoursPreferences);
          updateTeamDefaultHourlyRate(team.defaultHourlyRate);
          updateTeamDefaultDailyRate(team.defaultDailyRate);
        }
      });

      setTeams([...getTeamMembersByAuthTokenData]);
    }
  };

  /* Get user info call */
  const fetchUsersByAuthTokenPromise = async () => {
    await dynamicApiCall({
      callName: 'getUsersByAuthToken',
      params: {
        setUserData,
        setUpdatingUserData,
      },
    });
  };

  /* Get auth token validity */
  const fetchAuthTokenValidityPromise = async (authToken) => {
    await dynamicApiCall({
      callName: 'getAuthTokenValidity',
      navigate,
      params: {
        authToken,
      },
    });
  };

  /* Combine both call to send  */
  const getUserInfoAndTeam = async () => {
    await fetchTeamMembersByUsersUUIDPromise();
    await fetchUsersByAuthTokenPromise();
  };

  useEffect(() => {
    /* Check if an authToken is present in localStorage */
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      fetchAuthTokenValidityPromise(authToken);
    } else if (authToken === null) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    getUserInfoAndTeam();
  }, [teamName]);
  /* END CALL API */

  useEffect(() => {
    setPasswordsMatch(true);
  }, [updatingUserData.newPassword, updatingUserData.newPasswordConfirm]);

  const handleTeamClick = (teamUUID, e) => {
    e.preventDefault();

    // Update the isSelected property in the state
    const updatedTeams = teams.map((team) => ({
      ...team,
      isSelected: team.uuid === teamUUID,
    }));

    const clickedTeamName = updatedTeams.find(
      (team) => team.uuid === teamUUID
    ).name;

    updateTeamName(clickedTeamName);
    setTeams(updatedTeams);
    updateTeamUUID(teamUUID);
    localStorage.setItem('tmx', teamUUID);

    /* Check if we are on path that require the teamName as params */
    const currentPathName = window.location.pathname;
    const pathSegments = currentPathName.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const requiresTeamName = currentPathName.includes('/settings');

    /* Then navigate through it to refresh url with correct team name */
    if (requiresTeamName) {
      navigate(
        `/settings/teams/${encodeURIComponent(clickedTeamName)}/${lastSegment}`
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    /* Spread the existing data */
    /* Update the specific property by name */

    setUpdatingUserData({
      ...updatingUserData,
      [name]: value,
    });
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <div className="flex items-center w-12 h-12">
                      <LogoSvg />
                    </div>
                    <span className="truncate ml-3 text-white font-semibold w-full">
                      {teams.map((team) => (team.isSelected ? teamName : null))}
                    </span>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                className={classNames(
                                  item.current
                                    ? 'bg-indigo-700 text-white'
                                    : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? 'text-white'
                                      : 'text-indigo-200 group-hover:text-white',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li id="yourTeams">
                        <div className="text-xs font-semibold leading-6 text-indigo-200">
                          Your teams
                        </div>
                        {teams.length > 0 ? (
                          <>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              {teams.map((team) => (
                                <li key={team.uuid}>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      handleTeamClick(team.uuid, e);
                                    }}
                                    className={classNames(
                                      team.isSelected
                                        ? 'text-white w-full'
                                        : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                                      'group flex items-center justify-start rounded-md p-2 text-sm font-semibold leading-6 w-full'
                                    )}
                                  >
                                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
                                      {team.initial}
                                    </span>
                                    <span className="truncate ml-2">
                                      {team.name}
                                    </span>
                                    {team.isSelected && (
                                      <CheckIcon className="h-5 w-5 text-white ml-auto" />
                                    )}
                                  </button>
                                </li>
                              ))}
                            </ul>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                              <li>
                                <button
                                  type="button"
                                  onClick={() => {
                                    navigate(
                                      `/settings/teams/${encodeURIComponent(
                                        teamName
                                      )}/plans&billing`
                                    );
                                  }}
                                  className={`${
                                    window.location.pathname.includes(
                                      '/settings'
                                    )
                                      ? 'bg-indigo-700 text-white'
                                      : ''
                                  } text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center`}
                                >
                                  <Cog6ToothIcon
                                    className="-mr-1 h-5 w-5 text-indigo-200 hover:text-white"
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">
                                    Team settings
                                  </span>
                                </button>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <div className="ml-2 mt-2 text-sm text-indigo-300">
                            No team
                          </div>
                        )}
                        <div className="border-t border-indigo-500 mx-2 mt-4" />
                        <ul role="list" className="-mx-2 mt-2 space-y-1">
                          <li>
                            <button
                              type="button"
                              onClick={() =>
                                navigate('/team/create', {
                                  state: {
                                    isFirstTeam: false,
                                  },
                                })
                              }
                              className="text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center"
                            >
                              <PlusIcon
                                className="-mr-1 h-5 w-5 text-indigo-200 hover:text-white"
                                aria-hidden="true"
                              />
                              <span className="truncate">Create new team</span>
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6">
          <div className="flex h-16 shrink-0 items-center">
            <div className="flex items-center w-12 h-12">
              <LogoSvg
                startColor="rgb(255 255 255)"
                endColor="rgb(255 255 255)"
              />
            </div>
            <span className="truncate ml-3 text-white font-semibold w-full">
              {teams.map((team) => (team.isSelected ? teamName : null))}
            </span>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name} id={`menu${item.name}`}>
                      <Link
                        id={`menuLink${item.name}`}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-indigo-700 text-white'
                            : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-white'
                              : 'text-indigo-200 group-hover:text-white',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              <li id="yourTeams">
                <div className="text-xs font-semibold leading-6 text-indigo-200">
                  Your teams
                </div>
                {teams.length > 0 ? (
                  <>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      {teams.map((team) => (
                        <li key={team.uuid}>
                          <button
                            type="button"
                            onClick={(e) => {
                              handleTeamClick(team.uuid, e);
                            }}
                            className={classNames(
                              team.isSelected
                                ? 'text-white w-full'
                                : 'text-indigo-200 hover:text-white hover:bg-indigo-700',
                              'group flex items-center justify-start rounded-md p-2 text-sm font-semibold leading-6 w-full'
                            )}
                          >
                            <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-indigo-400 bg-indigo-500 text-[0.625rem] font-medium text-white">
                              {team.initial}
                            </span>
                            <span className="truncate ml-2">{team.name}</span>
                            {team.isSelected && (
                              <CheckIcon className="h-5 w-5 text-white ml-auto" />
                            )}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <ul role="list" className="-mx-2 mt-2 space-y-1">
                      <li>
                        <button
                          type="button"
                          onClick={() => {
                            navigate(
                              `/settings/teams/${encodeURIComponent(
                                teamName
                              )}/plans&billing`
                            );
                          }}
                          className={`${
                            window.location.pathname.includes('/settings')
                              ? 'bg-indigo-700 text-white'
                              : ''
                          } text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center`}
                        >
                          <Cog6ToothIcon
                            className="-mr-1 h-5 w-5 text-indigo-200 hover:text-white"
                            aria-hidden="true"
                          />
                          <span className="truncate">Team settings</span>
                        </button>
                      </li>
                    </ul>
                  </>
                ) : (
                  <div className="ml-2 mt-2 text-sm text-indigo-300">
                    No team
                  </div>
                )}
                <div className="border-t border-indigo-500 mx-2 mt-4" />
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <li>
                    <button
                      type="button"
                      onClick={() =>
                        navigate('/team/create', {
                          state: {
                            isFirstTeam: false,
                          },
                        })
                      }
                      className="text-indigo-200 hover:text-white hover:bg-indigo-700 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full items-center"
                    >
                      <PlusIcon
                        className="-mr-1 h-5 w-5 text-indigo-200 hover:text-white"
                        aria-hidden="true"
                      />
                      <span className="truncate">Create new team</span>
                    </button>
                  </li>
                </ul>
              </li>

              <li className="-mx-6 mt-auto">
                <Popover className="relative">
                  <Popover.Button className="focus:outline-none flex w-full items-center gap-x-4 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700">
                    <AvatarWithPlaceholder
                      size="sm"
                      placeholder={
                        (userData?.firstName
                          ? userData.firstName.charAt(0)
                          : '') +
                        (userData?.lastName ? userData.lastName.charAt(0) : '')
                      }
                    />
                    <span className="sr-only">Your profile</span>
                    <span className="w-full" aria-hidden="true">
                      {userData?.firstName && userData?.lastName ? (
                        `${userData.firstName} ${userData.lastName}`
                      ) : userData?.mail ? (
                        userData.mail
                      ) : (
                        <PulseLoader />
                      )}
                    </span>
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Panel className="absolute z-10 shadow-lg w-screen max-w-min -bottom-full left-1/2 transform -translate-x-1/2 -translate-y-full">
                      <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                        {[
                          {
                            name: 'Product tour',
                            icon: (
                              <QuestionMarkCircleIcon
                                className="-ml-0.5 mr-1.5 h-5 w-5"
                                aria-hidden="true"
                              />
                            ),
                            onClick: () => {
                              localStorage.removeItem('guideStarted');
                              setShouldRestartGuidedTour(true);
                            },
                          },
                          {
                            name: 'Reset password',
                            icon: (
                              <KeyIcon
                                className="-ml-0.5 mr-1.5 h-5 w-5"
                                aria-hidden="true"
                              />
                            ),
                            onClick: () => {
                              updateOpenModal(!openModal);
                            },
                          },
                          {
                            name: 'Sign out',
                            icon: (
                              <ArrowLeftOnRectangleIcon
                                className="-ml-0.5 mr-1.5 h-5 w-5"
                                aria-hidden="true"
                              />
                            ),
                            onClick: () => {
                              localStorage.removeItem('authToken');
                              navigate('/login');
                            },
                          },
                        ].map((item) => (
                          <button
                            type="button"
                            key={item.name}
                            className="flex p-2 hover:text-indigo-600"
                            onClick={item.onClick}
                          >
                            {item.icon}
                            {item.name}
                          </button>
                        ))}
                      </div>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-indigo-600 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-indigo-200 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">
          Menu
        </div>
        <div>
          <Popover className="relative">
            <Popover.Button className="focus:outline-none flex w-full items-center gap-x-4 px-2 py-3 text-sm font-semibold leading-6 text-white hover:bg-indigo-700">
              <AvatarWithPlaceholder
                size="sm"
                placeholder={
                  (userData?.firstName ? userData.firstName.charAt(0) : '') +
                  (userData?.lastName ? userData.lastName.charAt(0) : '')
                }
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Popover.Panel className="absolute z-10 shadow-lg w-screen max-w-min top-full transform -translate-x-1/2 ">
                <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                  {[
                    {
                      name: 'Product tour',
                      icon: (
                        <QuestionMarkCircleIcon
                          className="-ml-0.5 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        localStorage.removeItem('guideStarted');
                        setShouldRestartGuidedTour(true);
                      },
                    },
                    {
                      name: 'Reset password',
                      icon: (
                        <KeyIcon
                          className="-ml-0.5 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        updateOpenModal(!openModal);
                      },
                    },
                    {
                      name: 'Sign out',
                      icon: (
                        <ArrowLeftOnRectangleIcon
                          className="-ml-0.5 mr-1.5 h-5 w-5"
                          aria-hidden="true"
                        />
                      ),
                      onClick: () => {
                        localStorage.removeItem('authToken');
                        navigate('/login');
                      },
                    },
                  ].map((item) => (
                    <button
                      type="button"
                      key={item.name}
                      className="flex p-2 hover:text-indigo-600"
                      onClick={item.onClick}
                    >
                      {item.icon}
                      {item.name}
                    </button>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
        {/* <Link onClick={() => updateOpenModal(!openModal)}>
          <span className="sr-only">Your profile</span>
          <AvatarWithPlaceholder
            size="sm"
            placeholder={
              (userData?.firstName ? userData.firstName.charAt(0) : '') +
              (userData?.lastName ? userData.lastName.charAt(0) : '')
            }
          />
        </Link> */}
      </div>

      <main className="h-screen py-10 lg:pl-72">
        <div className="h-full px-4 sm:px-6 lg:px-8">{children}</div>
      </main>

      {/* Modal update settings */}
      <Modal open={openModal} setOpen={updateOpenModal}>
        <form
          className="space-y-6"
          onSubmit={handleSubmit(fetchPutUserByUUIDData)}
        >
          <AddMemberPopupHeader
            data={{
              uuid: updatingUserData?.uuid,
              mail: updatingUserData?.mail,
              firstName: updatingUserData?.firstName,
              lastName: updatingUserData?.lastName,
            }}
            isMailRequired
            register={register}
            errors={errors}
            handleInputChange={handleInputChange}
          />

          <div className="pt-2" />

          <TabsWithUnderline tabs={modalTabs} handleChange={updateModalTabs} />
          {modalTabs.map((tab, index) =>
            // Check if the current tab is selected
            tab.current ? (
              // Render the component associated with the current tab
              <div key={index}>
                {tab.name === 'Reset password' && (
                  <>
                    <LabelInput
                      label="Current password"
                      id="currentPassword"
                      type="password"
                      updateData={(e) =>
                        setUpdatingUserData({
                          ...updatingUserData,
                          currentPassword: e,
                        })
                      }
                      register={register}
                      errors={errors}
                      registerName="currentPassword"
                      registerValue={/^(?=.*[A-Za-z])(?=.*\d).{8,}$/}
                      registerMessage="Invalid password format"
                    />
                    <div className="mt-8" />
                    <LabelInput
                      label="New password"
                      id="newPassword"
                      type="password"
                      updateData={(e) =>
                        setUpdatingUserData({
                          ...updatingUserData,
                          newPassword: e,
                        })
                      }
                      register={register}
                      errors={errors}
                      registerName="newPassword"
                      registerValue={/^(?=.*[A-Za-z])(?=.*\d).{8,}$/}
                      registerMessage="Invalid password format"
                    />
                    <p className="text-xs mt-1 text-gray-500 italic">
                      Must be at least 8 characters long with letters and
                      numbers.
                    </p>
                    <div className="mt-2" />
                    <LabelInput
                      label="Confirm new password"
                      id="confirmNewPassword"
                      type="password"
                      updateData={(e) =>
                        setUpdatingUserData({
                          ...updatingUserData,
                          newPasswordConfirm: e,
                        })
                      }
                    />
                    {!passwordsMatch && (
                      <p className="text-red-500 text-sm mt-2">
                        Passwords do not match. Please try again.
                      </p>
                    )}
                  </>
                )}
              </div>
            ) : null
          )}

          <ModalHandleButtons
            label="Save"
            labelCancel="Cancel"
            onClickCancel={() => updateOpenModal(false)}
          />
        </form>
      </Modal>
    </div>
  );
};
