import { AvatarWithPlaceholder } from '../atoms/AvatarWithPlaceholder';

export const TableCellTitleSubtitleAndAvatar = ({
  data,
  label,
  sublabel,
  index,
  customClassName,
  badge = null,
}) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  return (
    <td
      className={classNames(
        data !== index.length - 1 ? 'border-b border-gray-200' : '',
        'whitespace-nowrap py-4 pr-3 text-xs text-gray-600 px-2',
        customClassName
      )}
    >
      <div className="flex items-center">
        <AvatarWithPlaceholder placeholder={label.charAt(0) || '-'} />
        <div className="ml-2 grid">
          <span className="font-medium">{label}</span>
          <div className="flex items-center">
            <span className="text-gray-500">{sublabel}</span>
            <div className="ml-1">{badge}</div>
          </div>
        </div>
      </div>
    </td>
  );
};
