// src/services/utils/mergeUtils.js

export function mergeClientAndProjectsForExport(client, projects) {
  const mergedClients = [];

  // Convert client to object format for easier lookup
  const clientMap = {};
  if (client && client.uuid) {
    clientMap[client.uuid] = { ...client, projects: [] };
  }

  // Iterate through projects and add them to the appropriate client
  if (Array.isArray(projects) && projects.length > 0) {
    projects.forEach((project) => {
      if (project.clientUUID && clientMap[project.clientUUID]) {
        const clientObj = clientMap[project.clientUUID];
        // Convert empty strings or empty values to null
        Object.keys(project).forEach((key) => {
          if (project[key] === '' || project[key] === undefined) {
            project[key] = null;
          }
        });
        clientObj.projects.push(project);
      }
    });
  }

  // Push the client to the mergedClients array, even if there are no projects
  if (clientMap[client.uuid]) {
    mergedClients.push(clientMap[client.uuid]);
  }

  // Add nbProjects property for each client
  mergedClients.forEach((client) => {
    client.nbProjects = client.projects.length;
  });

  return mergedClients;
}
