export const getTeamClientsLogic = async (datas) => {
  const { setClient, clientUUID, callData } = datas;

  if (clientUUID) {
    // Find the index of the object with the matching uuid
    const index = callData.findIndex((item) => item.uuid === clientUUID);

    // Check if the index is found
    if (index !== -1) {
      // If found, set the client using the found index
      setClient(callData[index]);
    } else {
      // If not found, handle the case (e.g., set to null or handle error)
      setClient(null);
    }
  } else {
    setClient(callData.length === 1 ? callData[0] : callData);
  }
};
