import { useEffect, useState, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useSlideOverFormContext } from './SlideOver';
import { InputErrorMessage } from '../atoms/InputErrorMessage';

export const SlideOverInputCheckbox = ({
  name,
  label,
  sublabel,
  isRequired,
  disabled = false,
  dependsOn,
}) => {
  const { register, errors, setValue, control } = useSlideOverFormContext();

  const [isDisabled, setIsDisabled] = useState(disabled);
  const [overlayAnimation, setOverlayAnimation] = useState('');
  const [isOverlayVisible, setIsOverlayVisible] = useState(isDisabled);
  const prevIsDisabledRef = useRef();

  // Watch the dependent field value
  const dependentValue = useWatch({ name: dependsOn, control });

  // Update `isDisabled` when the dependent value changes
  useEffect(() => {
    if (dependsOn) {
      const isAnySelected = dependentValue
        ? Object.values(dependentValue).some(Boolean)
        : false;
      setIsDisabled(!isAnySelected);
    } else {
      setIsDisabled(disabled);
    }
  }, [dependentValue, dependsOn, disabled]);

  // Reset the checkbox value when it becomes disabled
  useEffect(() => {
    if (isDisabled) {
      setValue(name, false);
    }
  }, [isDisabled, setValue, name]);

  // Handle overlay visibility and animation when `isDisabled` changes
  useEffect(() => {
    const prevIsDisabled = prevIsDisabledRef.current;
    prevIsDisabledRef.current = isDisabled;

    if (prevIsDisabled !== undefined && prevIsDisabled !== isDisabled) {
      if (isDisabled) {
        // Component became disabled
        setOverlayAnimation('fadeInRedToGray');
        setIsOverlayVisible(true);
      } else {
        // Component became enabled
        setOverlayAnimation('fadeOutGrayToGreen');

        // Keep the overlay visible during the fade-out animation
        setIsOverlayVisible(true);

        // Hide the overlay after the animation completes
        const timeoutId = setTimeout(() => {
          setIsOverlayVisible(false);
        }, 500); // Match this duration with the animation duration

        return () => clearTimeout(timeoutId);
      }
    } else {
      // Initial render
      setOverlayAnimation('');
      setIsOverlayVisible(isDisabled);
    }
  }, [isDisabled]);

  return (
    <div className="relative sm:col-span-2">
      {/* Diagonal Stripes Overlay */}
      {isOverlayVisible && (
        <div
          className="diagonal-stripes-overlay rounded-md"
          style={{
            animationName: overlayAnimation,
            animationDuration: '0.5s',
            animationFillMode: 'forwards',
          }}
        />
      )}

      <div className="relative">
        <div className="flex items-center">
          {/* Checkbox Input */}
          <input
            id={name}
            name={name}
            type="checkbox"
            {...register(name, {
              required: isRequired ? 'This input is required.' : false,
            })}
            className={`${
              isDisabled ? 'cursor-not-allowed bg-gray-100' : ''
            } h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2`}
            disabled={isDisabled}
          />

          {/* Label */}
          <label
            htmlFor={name}
            className={`${
              isDisabled ? 'cursor-not-allowed' : ''
            } text-sm text-gray-900`}
          >
            {label}
          </label>
        </div>

        <InputErrorMessage errors={errors} name={name} />

        {sublabel && (
          <p className="mt-1 text-sm font-light text-gray-500">{sublabel}</p>
        )}
      </div>
    </div>
  );
};
