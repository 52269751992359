import React, { useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { SmallCards } from '../molecules/SmallCards';
import { Checkbox } from '../atoms/Checkbox';
import { CalendarPicker } from '../atoms/CalendarPicker';
import { TreeCheckbox } from '../molecules/TreeCheckbox';
import SearchWithActions from '../molecules/SearchWithActions';

export const ModalExportContent = ({ itemsToExport, fetchExportData }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [budgetsDetails, setBudgetsDetails] = useState(false);
  const [allocationsDetails, setAllocationsDetails] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [treeData, setTreeData] = useState([]);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [treeVersion, setTreeVersion] = useState(0);
  const [checkedState, setCheckedState] = useState({});
  const [selectedClientsCount, setSelectedClientsCount] = useState(0);
  const [selectedProjectsCount, setSelectedProjectsCount] = useState(0);
  const [originalTreeData, setOriginalTreeData] = useState([]); // Store original unfiltered tree data

  const handleOptionChange = (option) => setSelectedOption(option);
  const handleBudgetsDetails = () => setBudgetsDetails((prev) => !prev);
  const handleAllocationsDetails = () => setAllocationsDetails((prev) => !prev);
  const handleDateChange = (from, to) => setDateRange({ from, to });

  useEffect(() => setSelectedOption({ id: 'csv' }), []);

  const initializeCheckedState = (items) =>
    items.reduce((acc, client) => {
      acc[client.id] = true;
      if (client.children) {
        client.children.forEach((project) => {
          acc[project.id] = true;
        });
      }
      return acc;
    }, {});

  // Map itemsToExport into treeData format and initialize state when itemsToExport is available
  useEffect(() => {
    if (itemsToExport && itemsToExport.length > 0) {
      const mappedTreeData = mapItemsToTreeData(itemsToExport);
      setTreeData(mappedTreeData);
      setOriginalTreeData(mappedTreeData); // Store the unfiltered original tree data
      setFilteredTreeData(mappedTreeData);

      const initialCheckedState = initializeCheckedState(mappedTreeData);
      setCheckedState(initialCheckedState);
    }
  }, [itemsToExport]);

  useEffect(() => {
    if (Object.keys(checkedState).length > 0) {
      updateSelectionCounts(checkedState);
    }
  }, [checkedState]);

  const mapItemsToTreeData = (items) =>
    items.map((client) => ({
      id: client.uuid,
      label: client.name,
      isChecked: true,
      children: client.projects
        ? client.projects.map((project) => ({
            id: project.uuid,
            label: project.name,
            isChecked: true,
          }))
        : [],
    }));

  const handleSearchChange = (query) => {
    const filterTree = (nodes) =>
      nodes
        .map((node) => {
          const children = node.children ? filterTree(node.children) : [];
          if (
            node.label.toLowerCase().includes(query.toLowerCase()) ||
            children.length > 0
          ) {
            return { ...node, children };
          }
          return null;
        })
        .filter((node) => node !== null);

    setFilteredTreeData(filterTree(treeData));
    setTreeVersion((prev) => prev + 1);
  };

  const selectAll = () => {
    const updatedCheckedState = { ...checkedState };
    const markAllChecked = (nodes) => {
      nodes.forEach((node) => {
        updatedCheckedState[node.id] = true;
        if (node.children) {
          markAllChecked(node.children);
        }
      });
    };
    markAllChecked(treeData);
    setCheckedState(updatedCheckedState);
    setTreeVersion((prev) => prev + 1);
    updateSelectionCounts(updatedCheckedState);
  };

  const unselectAll = () => {
    const updatedCheckedState = { ...checkedState };
    const markAllUnchecked = (nodes) => {
      nodes.forEach((node) => {
        updatedCheckedState[node.id] = false;
        if (node.children) {
          markAllUnchecked(node.children);
        }
      });
    };
    markAllUnchecked(treeData);
    setCheckedState(updatedCheckedState);
    setTreeVersion((prev) => prev + 1);
    updateSelectionCounts(updatedCheckedState);
  };

  const updateSelectionCounts = (updatedCheckedState) => {
    const { clientsCount, projectsCount } = countSelectedClientsAndProjects(
      treeData,
      updatedCheckedState
    );
    setSelectedClientsCount(clientsCount);
    setSelectedProjectsCount(projectsCount);
  };

  const countSelectedClientsAndProjects = (nodes, checkedState) => {
    let clientsCount = 0;
    let projectsCount = 0;

    nodes.forEach((client) => {
      if (checkedState[client.id]) {
        clientsCount += 1;
      }
      if (client.children && client.children.length > 0) {
        client.children.forEach((project) => {
          if (checkedState[project.id]) {
            projectsCount += 1;
          }
        });
      }
    });

    return { clientsCount, projectsCount };
  };

  const handleCheckChange = (updatedCheckedState) => {
    setCheckedState((prevState) => ({
      ...prevState,
      ...updatedCheckedState,
    }));
    updateSelectionCounts(updatedCheckedState);
  };

  const extractSelectedItems = (nodes, checkedState) =>
    nodes
      .filter((client) => checkedState[client.id])
      .map((client) => ({
        clientUUID: client.id,
        projectsUUIDs: (client.children || [])
          .filter((project) => checkedState[project.id])
          .map((project) => project.id),
      }));

  const isExportDisabled =
    allocationsDetails && (!dateRange.from || !dateRange.to);

  return (
    <>
      <p className="text-primary">Export your data</p>
      <div className="mt-2" />
      <p className="text-sm text-gray-500">
        Select the export type you prefer, and customize it to meet your needs.
      </p>
      <div className="mt-6" />
      <p className="text-secondary">Data type :</p>
      <SmallCards
        options={[
          { id: 'csv', name: 'CSV', isSelected: selectedOption?.id === 'csv' },
          {
            id: 'xlsx',
            name: 'Excel',
            isSelected: selectedOption?.id === 'xlsx',
          },
        ]}
        selectedOption={selectedOption}
        onChange={handleOptionChange}
      />
      <p className="text-xs font-light mt-2 flex">
        <InformationCircleIcon className="w-4 h-4 text-indigo-500 mr-1" />
        Export includes: {selectedClientsCount} clients &{' '}
        {selectedProjectsCount} projects.
      </p>
      <div className="mt-6" />
      <p className="text-secondary">Portfolio selection :</p>
      <div className="mt-2" />
      <SearchWithActions
        onSearchChange={handleSearchChange}
        onSelectAll={selectAll}
        onUnselectAll={unselectAll}
      />
      <TreeCheckbox
        key={treeVersion}
        data={filteredTreeData}
        checkedState={checkedState}
        onCheckChange={handleCheckChange}
        originalTreeData={originalTreeData} // Pass original tree data
      />

      <div className="mt-6" />
      <p className="text-secondary">Optional data :</p>

      <div className="mt-2" />
      <Checkbox
        name="budgetDetails"
        label="Budgets details"
        isChecked={budgetsDetails}
        onChange={handleBudgetsDetails}
      />
      <div className="mt-1" />
      <Checkbox
        name="projectAllocations"
        label="Projects allocations & members details"
        isChecked={allocationsDetails}
        onChange={handleAllocationsDetails}
      />

      {allocationsDetails && (
        <div className="mt-4">
          <CalendarPicker
            isRequired
            onChange={handleDateChange}
            defaultStartValue={dateRange.from}
            defaultEndValue={dateRange.to}
          />
        </div>
      )}

      <div className="mt-4 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          onClick={() => {
            const selectedItems = extractSelectedItems(treeData, checkedState);

            fetchExportData({
              format: selectedOption.id,
              isBudgetSelected: budgetsDetails,
              isAllocationsSelected: allocationsDetails,
              allocationStartDate: dateRange.from,
              allocationEndDate: dateRange.to,
              itemsToExport: selectedItems,
            });
          }}
          disabled={isExportDisabled}
          className={`flex justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            isExportDisabled
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-indigo-600 hover:bg-indigo-500'
          }`}
        >
          Generate export
        </button>
      </div>
    </>
  );
};

export default ModalExportContent;
