import React, { useEffect, useState } from 'react';
import { Checkbox } from '../atoms/Checkbox';

export const TreeCheckbox = ({
  data,
  checkedState,
  onCheckChange,
  originalTreeData,
  classNames = 'h-64',
}) => {
  const [localCheckedState, setLocalCheckedState] = useState({});

  useEffect(() => {
    setLocalCheckedState(checkedState || {});
  }, [checkedState]);

  const findNodeById = (id, nodes) => {
    for (const node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const childNode = findNodeById(id, node.children);
        if (childNode) return childNode;
      }
    }
    return null;
  };

  const findParentId = (id, nodes, parentId = null) => {
    for (const node of nodes) {
      if (node.id === id) {
        return parentId;
      }
      if (node.children) {
        const result = findParentId(id, node.children, node.id);
        if (result !== null) {
          return result;
        }
      }
    }
    return null;
  };

  const getParentId = (id) => findParentId(id, originalTreeData);

  const handleChange = (id, checked) => {
    const updatedCheckedState = { ...localCheckedState };
    updatedCheckedState[id] = checked;

    const currentNode = findNodeById(id, originalTreeData);

    const updateParentsToSelected = (nodeId) => {
      const parentId = getParentId(nodeId);
      if (parentId) {
        if (!updatedCheckedState[parentId]) {
          updatedCheckedState[parentId] = true;
        }
        updateParentsToSelected(parentId);
      }
    };

    const unselectDescendants = (node) => {
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          updatedCheckedState[child.id] = false;
          unselectDescendants(child);
        });
      }
    };

    if (currentNode) {
      if (checked) {
        // Node is being checked
        if (!currentNode.children || currentNode.children.length === 0) {
          // If it's a child node, ensure parents are checked
          updateParentsToSelected(id);
        }
        // If it's a parent node, do nothing to the children
      } else if (currentNode.children && currentNode.children.length > 0) {
        // Node is being unchecked and it's a parent node
        // Unselect all descendants
        unselectDescendants(currentNode);
      }
      // If it's a child node being unchecked, do nothing to the parent
    }

    setLocalCheckedState(updatedCheckedState);
    onCheckChange && onCheckChange(updatedCheckedState);
  };

  const renderCheckboxes = (nodes) =>
    nodes.map((node) => (
      <div key={node.id} className="mt-1 relative">
        <div className="flex items-center">
          <Checkbox
            name={node.id}
            label={`${node.label}`}
            isChecked={!!localCheckedState[node.id]}
            isDisabled={node.isDisabled}
            onChange={(e) => handleChange(node.id, e.target.checked)}
          />
        </div>
        {node.children && node.children.length > 0 && (
          <div className="ml-1.5 border-l-2 border-gray-100 pl-4">
            {renderCheckboxes(node.children)}
          </div>
        )}
      </div>
    ));

  return (
    <div className={`tree-checkbox overflow-y-auto ${classNames} pl-1`}>
      {renderCheckboxes(data)}
    </div>
  );
};

export default TreeCheckbox;
