import { exportDataInCsv, exportDataInXlsx } from '../../../utils/export';

export const getExportDataLogic = async (datas) => {
  const { callData, format, updateOpenNotification, updateNotificationData } =
    datas;

  if (callData && callData.length > 0) {
    if (format === 'csv') {
      exportDataInCsv(callData, 'data_export');
    } else if (format === 'xlsx') {
      exportDataInXlsx(callData, 'data_export');
    }
  } else {
    updateOpenNotification(true);
    updateNotificationData({ ...callData });
  }
};
